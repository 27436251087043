



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import {
  AIAddTo,
  AICPTBidTypes,
  RuleCheckFrequencies,
  ActionFrequencies,
  ARStatus,
  ForTypes,
  FromTypes,
  AIOperations,
  AIOperators,
  AIMatchTypes,
  ACFields,
  ARExcludeDays,
  AITypes,
  CITimeRanges,
  CIOperations,
  CIOperators,
  CIAndORTypes,
} from '@shared/enums/arenums';
import SelectCampaign from '../campaign/SelectCampaign1.vue';
import SelectAdGroup from '../keywords/SelectAdGroup1.vue';
import SelectApps from './SelectApps1.vue';
import SelectKeywords from './SelectKeywords1.vue';
import {
  CREATE_AR_RULE,
  GET_SLACKS,
  GET_EMAILS,
  UPDATE_AR_RULE,
  CREATE_AR_ADV_RULE,
  UPDATE_AR_ADV_RULE,
} from '../../utils/arrules-queries';
import { v4 as uuidv4 } from 'uuid';
import {
  GET_CAMPAIGN_GROUPS,
  GET_GOALS_FROM_APP,
} from 'src/utils/apollo-queries';
import DecisionAlert from '../DecisionAlert.vue';
import { LSS } from '@core/services';

export default Vue.extend({
  name: 'CreateRule',
  components: {
    SelectCampaign,
    SelectAdGroup,
    SelectApps,
    DecisionAlert,
    SelectKeywords,
  },
  data() {
    const today = moment().utc().format('YYYY-MM-DD');
    return {
      DecisionAlertIcon:2,
      filterArr: [],
      originalSource:[],
      originalActions:[],
      addOtherMail: false,
      externalEmailSelected: false,
      emailSelected: false,
      allEmailSelected: false,
      conditionPanel: [0],
      actionPanel:[0],
      settingPanel:0,
      goals: [],
      isIndefinite: false,
      dates: [],
      ruleRangePicker: null,
      ruleDateRangelModel: [],
      ruleDateRange: [],
      conditionRangePicker: null,
      conditionDateRangelModel: [],
      conditionDateRange: [],
      cITimeRangesEnum: CITimeRanges,
      //form page related
      sourceFormvalid: true,
      actionFormvalid: true,
      metaFormValid: true,
      selectAllIcon: 'mdi-crop-square',
      selectAllSlackIcon: 'mdi-crop-square',
      slackChannelList: [],
      //form page related

      ruleName: null,
      rId: null,
      editData: null,
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      modeText: this.$t('goals.create'),
      decisionAlertData: {},
      showCreateAR: false,
      mode: 1,
      step: 1,
      aiConditionFormvalid: true,
      aiItemFormvalid: true,
      source: [
        {
          fromType: { levelName: 'Select', levelId: 0, value: '' },
          forType: { levelName: 'Select', levelId: 0, value: '' },
          andOrType: CIAndORTypes.AND,
          ruleType: 'ASA',
          from: [
            /* {
                    orgId: "2244050",
                    campaignId: "123412341234"
                  } */
          ],
          conditions: [
            {
              fieldpath: null,
              goalId: null,
              operation: null,
              operator: null,
              fromValue: null,
              toValue: null,
              value: null,
            },
          ],
        },
      ],
      fromLevels: [],
      AllFromLevels: [
        { levelName: 'Select', levelId: 0, value: '' },
        { levelName: 'Organization(s)', levelId: 1, value: FromTypes.ORG },
        { levelName: 'Application(s)', levelId: 2, value: FromTypes.APP },
        { levelName: 'Campaign(s)', levelId: 3, value: FromTypes.CAMPAIGN },
        { levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
        { levelName: 'Keyword(s)', levelId: 5, value: FromTypes.KEYWORD },
      ],
      selectedOrgs: [],
      selectedActionOrgs: [],
      orgList: [],
      ciFieldListStatic: [
        {
          header: 'ASA Fields',
          divider: true,
        },
        { field: 'Spend', id: 1, type: 1, value: ACFields.SPEND },
        { field: 'Installs', id: 2, type: 2, value: ACFields.INSTALLS },
        { field: 'Impressions', id: 3, type: 2, value: ACFields.IMPRESSIONS },
        { field: 'CPA', id: 4, type: 1, value: ACFields.CPA },
        { field: 'CPT', id: 5, type: 1, value: ACFields.CPT },
        { field: 'TTR', id: 6, type: 2, value: ACFields.TTR },
        {
          field: 'Lat On Installs',
          id: 7,
          type: 2,
          value: ACFields.LAT_ON_INSTALLS,
        },
        {
          field: 'Lat Off Installs',
          id: 8,
          type: 2,
          value: ACFields.LAT_OFF_INSTALLS,
        },
        {
          field: 'Conversion Rate',
          id: 9,
          type: 2,
          value: ACFields.CONVERSION_RATE,
        },
        {
          field: 'New Downloads',
          id: 10,
          type: 2,
          value: ACFields.NEW_DOWNLOADS,
        },
        {
          field: 'Re Downloads',
          id: 11,
          type: 2,
          value: ACFields.RE_DOWNLOADS,
        },
        { field: 'Taps', id: 12, type: 2, value: ACFields.TAPS },
      ],
      ciFieldList: [
        [
          {
            header: 'ASA Fields',
            divider: true,
          },
          { field: 'Spend', id: 1, type: 1, value: ACFields.SPEND },
          { field: 'Installs', id: 2, type: 2, value: ACFields.INSTALLS },
          { field: 'Impressions', id: 3, type: 2, value: ACFields.IMPRESSIONS },
          { field: 'CPA', id: 4, type: 1, value: ACFields.CPA },
          { field: 'CPT', id: 5, type: 1, value: ACFields.CPT },
          { field: 'TTR', id: 6, type: 2, value: ACFields.TTR },
          {
            field: 'Lat On Installs',
            id: 7,
            type: 2,
            value: ACFields.LAT_ON_INSTALLS,
          },
          {
            field: 'Lat Off Installs',
            id: 8,
            type: 2,
            value: ACFields.LAT_OFF_INSTALLS,
          },
          {
            field: 'Conversion Rate',
            id: 9,
            type: 2,
            value: ACFields.CONVERSION_RATE,
          },
          {
            field: 'New Downloads',
            id: 10,
            type: 2,
            value: ACFields.NEW_DOWNLOADS,
          },
          {
            field: 'Re Downloads',
            id: 11,
            type: 2,
            value: ACFields.RE_DOWNLOADS,
          },
          { field: 'Taps', id: 12, type: 2, value: ACFields.TAPS },
        ],
      ],
      ciFieldListWithMMP: [
        {
          header: 'ASA Fields',
          divider: true,
        },
        { field: 'Spend', id: 1, type: 1, value: ACFields.SPEND },
        { field: 'Installs', id: 2, type: 2, value: ACFields.INSTALLS },
        { field: 'Impressions', id: 3, type: 2, value: ACFields.IMPRESSIONS },
        { field: 'CPA', id: 4, type: 1, value: ACFields.CPA },
        { field: 'CPT', id: 5, type: 1, value: ACFields.CPT },
        { field: 'TTR', id: 6, type: 2, value: ACFields.TTR },
        {
          field: 'Lat On Installs',
          id: 7,
          type: 2,
          value: ACFields.LAT_ON_INSTALLS,
        },
        {
          field: 'Lat Off Installs',
          id: 8,
          type: 2,
          value: ACFields.LAT_OFF_INSTALLS,
        },
        {
          field: 'Conversion Rate',
          id: 9,
          type: 2,
          value: ACFields.CONVERSION_RATE,
        },
        {
          field: 'New Downloads',
          id: 10,
          type: 2,
          value: ACFields.NEW_DOWNLOADS,
        },
        {
          field: 'Re Downloads',
          id: 11,
          type: 2,
          value: ACFields.RE_DOWNLOADS,
        },
        { field: 'Taps', id: 12, type: 2, value: ACFields.TAPS },
        {
          header: 'Attribution Fields',
          divider: true,
        },
        {
          field: 'Goal Event Count',
          id: 13,
          type: 2,
          value: ACFields.GOAL_EVENT_COUNT,
        },
        {
          field: 'Cost Per Goal',
          id: 14,
          type: 1,
          value: ACFields.COST_PER_GOAL,
        },
        {
          field: 'Revenue Per Goal',
          id: 15,
          type: 1,
          value: ACFields.REVENUE_PER_GOAL,
        },
        {
          field: 'Goal Revenue',
          id: 16,
          type: 1,
          value: ACFields.GOAL_REVENUE,
        },
        { field: 'Goal ROAS', id: 17, type: 1, value: ACFields.GOAL_ROAS },
        { field: 'Goal ARPU', id: 18, type: 1, value: ACFields.GOAL_ARPU },
      ],
      allGoalItems: [],
      cIOperationsEnum: CIOperations,
      aIOperationsEnum: AIOperations,
      aCFieldsEnum: ACFields,
      AITypes: AITypes,
      vSelectEmailRules: [
        (v) => ((this.externalEmailSelected && this.addOtherMail) || this.emailSelected || this.allEmailSelected) || this.$t('createAR.pleaseSelect'),
        (v) => ((this.externalEmailSelected && this.addOtherMail) || this.emailSelected || this.allEmailSelected) || this.$t('createAR.pleaseSelect'),
      ],
      vSelectEmailRules1:{
        check(checkbox){
          return v => (checkbox|| v != '') || "Please select Email";
        }
      },
      vSelectNotNullRules: [
        (v) => v != null || this.$t('createAR.pleaseSelect'),
        (v) => v != '' || this.$t('createAR.pleaseSelect'),
      ],
      vSelectNotNullObjectRules: [
        (v) => v != null || this.$t('createAR.pleaseSelect'),
        (v) => v.value != '' || this.$t('createAR.pleaseSelect'),
      ],
      emptyStringRule: [
        (v) => v != '' || this.$t('createAR.pleaseEnteraValue'),
        (v) => v != 0 || this.$t('createAR.pleaseEnteraValue'),
        (v) => v != null || this.$t('createAR.pleaseEnteraValue'),
      ],
      csvEmailRules: [
        (v) => !!v || 'Please enter proper email ids',
        (v) =>
          /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/.test(
            v
          ) || this.$t('createAR.enterEmail'),
      ],
      vSelectRules: [(v) => v != null || this.$t('createAR.pleaseSelect')],
      nameRule: [
        (v) => v != '' || this.$t('createAR.pleaseEnteraValue'),
        (v) => v != 0 || this.$t('createAR.pleaseEnteraValue'),
        (v) => v != null || this.$t('createAR.pleaseEnteraValue'),
        //(v) => !((this.names.some(s => s.toLowerCase() === v.toLowerCase())) && (this.mode === 2)) || 'Rule name already exists'
        (v) => {
          if ((this.mode === 1 || this.mode === 5) && v) {
            return (
              (this.names && !this.names.some((s) => s === v.toLowerCase().trim())) ||
              this.$t('createAR.ruleAlreadyExist')
            );
          } else if (this.mode === 2) {
            if(v.toLowerCase().trim() === this.rName) {
                return true
              }
              else {
                return (this.names && !this.names.some((s) => s === v.toLowerCase().trim())) || this.$t('createAR.ruleAlreadyExist')
              }
            return true;
          } else {
            return true;
          }
        },
      ],
      //popup related
      selectCampaign: null,
      selectCampaignForKeywords: null,
      selectAdgroupForKeywords: null,
      selectAdgroupForChoose: null,
      toSelectApps: null,
      toSelectKeys: null,

      //action related
      actions: [
        {
          actionId:uuidv4(),
          target: {
            fromType: { levelName: 'Select', levelId: 0, value: '' },
            forType: { levelName: 'Select', levelId: 0, value: '' },
            from: [],
          },
          action: {
            type: null,
            aiEmail: [],
            aiSlack: [],
            selectAllEmails: false,
            selectAllIcon: 'mdi-crop-square',
            selectAllSlacks: false,
            selectAllSlackIcon: 'mdi-crop-square',
            aiOperation: null,
            aiOperators: null,
            aiValue: null,
          },
        },
      ],
      aiExtChecked: [false],
      externalEmailId: [[]],
      AllActionItems: [
        { action: this.$t('createAR.emailNotification'), id: 1, value: AITypes.EMAIL_NOTIF },
        { action: this.$t('createAR.slackNotification'), id: 2, value: AITypes.SLACK_NOTIF },
        { action: this.$t('createAR.pause'), id: 3, value: AITypes.PAUSE },
        { action: this.$t('createAR.resume'), id: 9, value: AITypes.RESUME },
        { action: this.$t('createAR.changeDailyCap'), id: 4, value: AITypes.CHANGE_DAILY_CAP },
        { action: this.$t('createAR.changeBid'), id: 5, value: AITypes.CHANGE_BID },
        { action: this.$t('createAR.changeBudget'), id: 6, value: AITypes.CHANGE_BUDGET },
        /*         { action: 'Add As Keyword', id: 7, value: AITypes.ADD_AS_KEYWORD },
         */ {
          action: this.$t('createAR.addAsNegativeKeyword'),
          id: 8,
          value: AITypes.ADD_AS_NEG_KEYWORD,
        },
      ],
      conditionalActionList: [],
      actionFromLevels: [],
      emailList: [],
      settings: {
          conditionSettings: {
            timeRange: CITimeRanges.LAST_30_DAYS,
            andOr: CIAndORTypes.AND
          },
          ruleSettings: {
            checkFrequency: RuleCheckFrequencies.EVERY_DAY,
            isDated: null,
            dateRange: null,
            isIndefinite: null
          }
        },
      selectAllEmails: false,
      selectAllSlacks: false,
    };
  },
  computed: {
    sortedActions(){
      const uniqId = this.actions.filter(el=>!el.copy).map(el=>el.actionId);
      let sortAction = [...this.actions]
      let arr = []
        for(const id of uniqId){
          let i=0;
           sortAction = sortAction.map(el => {
            if(el.actionId == id){
                i++
                return{...el,['sort'] : i}
              }
              else{
                return {...el}
              }
          })
        }
        /* uniqId.forEach(element,index => {
            let j=1;
            arr = this.actions.map(el, i => {
              if(el.actionId == element){
                el.sort = j
                j++
              }
            })
        }); */
        return sortAction
    },
    filteredActions(){
      return this.actions.filter(el=>el.copy!=true)/* .sort((a,b)=>a.pop-b.pop) */
    },

    isSourceValid() {
      let valid = true;
      for (const subRule of this.source) {
        if (!subRule.from.length) {
          valid = false;
          break;
        }
      }
      return valid;
    },
    isActionValid() {
      let valid = true;
      for (const action of this.actions) {
        if (!action.target.from.length) {
          valid = false;
          break;
        }
      }
      return valid;
    },
    ruleDateRangeText() {
      if (this.ruleDateRange[0] > this.ruleDateRange[1]) {
        const maxDate = this.ruleDateRange[0];
        this.ruleDateRange[0] = this.ruleDateRange[1];
        this.ruleDateRange[1] = maxDate;
      }
      return this.ruleDateRange.join(' - ');
    },
    conditionDateRangeText() {
      if (this.conditionDateRange[0] > this.conditionDateRange[1]) {
        const maxDate = this.conditionDateRange[0];
        this.conditionDateRange[0] = this.conditionDateRange[1];
        this.conditionDateRange[1] = maxDate;
      }
      return this.conditionDateRange.join(' - ');
    },
    andOrTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.andOrTypesText1'),
            value: CIAndORTypes.AND,
          },
          { text: this.$t('createAR.andOrTypesText2'), value: CIAndORTypes.OR },
        ];
      },
    },

    forInputRuleFor: {
      get() {
        return [
          { levelName: this.$t('labels.selectLevel'), levelId: 0, value: '' },
          { levelName: this.$t('labels.org'), levelId: 1, value: ForTypes.ORG },
          { levelName: this.$t('labels.app'), levelId: 2, value: ForTypes.APP },
          {
            levelName: this.$t('labels.camp'),
            levelId: 3,
            value: ForTypes.CAMPAIGN,
          },
          {
            levelName: this.$t('labels.adGroup'),
            levelId: 4,
            value: ForTypes.ADGROUP,
          },
          {
            levelName: this.$t('labels.key'),
            levelId: 5,
            value: ForTypes.KEYWORD,
          },
          //{ levelName: this.$t('labels.searchTerm'), levelId: 6, value: ForTypes.SEARCHTERM },
        ];
      },
    },

    addToModelListNeg: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.addToModelListNegText1'),
            value: AIAddTo.ALL_SELECTED_ADGROUPS,
          },
          {
            text: this.$t('createAR.addToModelListNegText2'),
            value: AIAddTo.ALL_ADGROUPS_SELECTED_CAMPAIGNS,
          },
          {
            text: this.$t('createAR.addToModelListNegText3'),
            value: AIAddTo.ALL_SELECTED_CAMPAIGNS,
          },
        ];
      },
    },

    addToModelList: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.addToModelListNegText1'),
            value: AIAddTo.ALL_SELECTED_ADGROUPS,
          },
          {
            text: this.$t('createAR.addToModelListNegText2'),
            value: AIAddTo.ALL_ADGROUPS_SELECTED_CAMPAIGNS,
          },
        ];
      },
    },

    matchTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.matchTypesText1'),
            value: AIMatchTypes.BROAD,
          },
          {
            text: this.$t('createAR.matchTypesText2'),
            value: AIMatchTypes.EXACT,
          },
        ];
      },
    },

    cptBidItems: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.cptBidItemsText1'),
            value: AICPTBidTypes.SET_TO_CUSTOM,
          },
          {
            text: this.$t('createAR.cptBidItemsText2'),
            value: AICPTBidTypes.ADGROUP_DEFAULT_BID,
          },
        ];
      },
    },

    actionFreqs: {
      cache: false,
      get() {
        return [
          //{ text: this.$t('createAR.actionFreqsText1'), value: RuleCheckFrequencies.HOURLY },
          {
            text: this.$t('createAR.actionFreqsText2'),
            value: RuleCheckFrequencies.EVERY_6_HRS,
          },
          {
            text: this.$t('createAR.actionFreqsText3'),
            value: RuleCheckFrequencies.EVERY_12_HRS,
          },
          {
            text: this.$t('createAR.actionFreqsText4'),
            value: RuleCheckFrequencies.EVERY_DAY,
          },
          //{ text: this.$t('createAR.actionFreqsText5'), value: RuleCheckFrequencies.CUSTOM },
        ];
      },
    },

    limitActionFreqs: {
      cache: false,
      get() {
        return [
          //{ text: this.$t('createAR.actionFreqsText1'), value: ActionFrequencies.HOURLY },
          //{ text: this.$t('createAR.limitActionFreqsText1'), value: ActionFrequencies.EVERY_2_HRS },
          //{ text: this.$t('createAR.limitActionFreqsText2'), value: ActionFrequencies.EVERY_3_HRS },
          {
            text: this.$t('createAR.actionFreqsText2'),
            value: ActionFrequencies.EVERY_6_HRS,
          },
          {
            text: this.$t('createAR.actionFreqsText3'),
            value: ActionFrequencies.EVERY_12_HRS,
          },
          {
            text: this.$t('createAR.actionFreqsText4'),
            value: ActionFrequencies.EVERY_DAY,
          },
          {
            text: this.$t('createAR.limitActionFreqsText3'),
            value: ActionFrequencies.EVERY_2ND_DAY,
          },
          {
            text: this.$t('createAR.limitActionFreqsText4'),
            value: ActionFrequencies.EVERY_3RD_DAY,
          },
          {
            text: this.$t('createAR.limitActionFreqsText5'),
            value: ActionFrequencies.EVERY_WEEK,
          },
        ];
      },
    },

    excludeDays: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.mon'), value: ARExcludeDays.MON },
          { text: this.$t('createAR.tue'), value: ARExcludeDays.TUE },
          { text: this.$t('createAR.wed'), value: ARExcludeDays.WED },
          { text: this.$t('createAR.thu'), value: ARExcludeDays.THU },
          { text: this.$t('createAR.fri'), value: ARExcludeDays.FRI },
          { text: this.$t('createAR.sat'), value: ARExcludeDays.SAT },
          { text: this.$t('createAR.sun'), value: ARExcludeDays.SUN },
        ];
      },
    },

    ciTimeRanges: {
      cache: false,
      get() {
        return [
          //{ text: this.$t('createAR.ciTimeRangesText1'), value: CITimeRanges.LAST_1_HOUR },
          {
            text: this.$t('createAR.ciTimeRangesText2'),
            value: CITimeRanges.LAST_1_DAY,
          },
          {
            text: this.$t('createAR.ciTimeRangesText3'),
            value: CITimeRanges.LAST_7_DAYS,
          },
          {
            text: this.$t('createAR.ciTimeRangesText4'),
            value: CITimeRanges.LAST_30_DAYS,
          },
          //{ text: this.$t('createAR.ciTimeRangesText5'), value: CITimeRanges.THIS_MONTH },
          //{ text: this.$t('createAR.ciTimeRangesText6'), value: CITimeRanges.CUSTOM_HOUR },
          {
            text: this.$t('createAR.ciTimeRangesText7'),
            value: CITimeRanges.DATE_RANGE,
          },
        ];
      },
    },

    allConOperationItems: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.allConOperationText1'),
            value: CIOperations.GREATER_THAN,
          },
          {
            text: this.$t('createAR.allConOperationText2'),
            value: CIOperations.LESS_THAN,
          },
          {
            text: this.$t('createAR.allConOperationText3'),
            value: CIOperations.IN_RANGE,
          },
          {
            text: this.$t('createAR.allConOperationText4'),
            value: CIOperations.NOT_IN_RANGE,
          },
        ];
      },
    },

    allConOperatorItems: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.amount'),
            value: CIOperators.DOLLAR_AMOUNT,
          },
          {
            text: this.$t('createAR.budget'),
            value: CIOperators.PERCENTAGE_OF_BUDGET,
          },
        ];
      },
    },

    allConOperatorItems1: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.amount'),
            value: CIOperators.DOLLAR_AMOUNT,
          },
        ];
      },
    },

    aiOperationList: {
      cache: false,
      get() {
        return [
          //{ text: 'Set to', value: AIOperations.SET_TO},
          { text: this.$t('createAR.decBy'), value: AIOperations.DECREASE_BY },
          { text: this.$t('createAR.incBy'), value: AIOperations.INCREASE_BY },
        ];
      },
    },

    aiOperationList2: {
      cache: false,
      get() {
        return [
          //{ text: 'Set to', value: AIOperations.SET_TO},
          { text: this.$t('createAR.incBy'), value: AIOperations.INCREASE_BY },
        ];
      },
    },

    aiOperationList3: {
      cache: false,
      get() {
        return [
          //{ text: 'Set to', value: AIOperations.SET_TO},
          { text: this.$t('createAR.decBy'), value: AIOperations.DECREASE_BY },
          { text: this.$t('createAR.incBy'), value: AIOperations.INCREASE_BY },
          //{ text: this.$t('createAR.setToCPA'), value: AIOperations.SET_TO_AVG_CPA },
          //{ text: this.$t('createAR.setToCPT'), value: AIOperations.SET_TO_AVG_CPT },
        ];
      },
    },

    aiOperatorsList: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.value'), value: AIOperators.VALUE },
          { text: this.$t('createAR.change'), value: AIOperators.PERCENTAGE },
        ];
      },
    },
  },
  props: {
    fromRulesPage: {
      type: Object,
      required: false,
    },
  },
  watch: {
    fromRulesPage: function (data) {
      this.showCreateAR = true;
      this.mode = data.mode;
      this.names = data.names || [];

      if (data.mode == 2) {
        this.modeText = this.$t('createAR.update');
        this.editData = data.item;
        this.goals = data.goals;
        //this.mongoId = data.item._id;
        this.rId = data.item.ruleId;
        this.rName = data.item.ruleName.toLowerCase().trim();
        this.initEdit();
      }
      else if(data.mode == 3){
        this.modeText = 'View';
        this.editData = data.item;
        this.goals = data.goals;
        //this.mongoId = data.item._id;
        this.rId = data.item.ruleId;
        this.rName = data.item.ruleName.toLowerCase().trim();
        this.initEdit();
      }
      else if(data.mode === 5){
        data.item.ruleId = null
        const name = data.item.ruleName.toLowerCase().trim() + ' - copy - ' + Date.now().toString().substring(Date.now().toString().length - 6);
        data.item.ruleName = name
        this.modeText = 'Create';
        this.editData = data.item;
        this.goals = data.goals;
        this.rId = null;
        this.rName = name
        this.initEdit();
      }
      else {
        this.modeText = this.$t('goals.create');
        this.editData = null;
        this.rId = null;
        //this.mongoId = null;
        //this.clearFor()
      }
    },
  },
  methods: {
    validate(){
      this.$refs.source.validate()
    },
    visibleDelButton(actionId){
      const length =  this.actions.filter(el=>el.actionId==actionId).length
      if(length>1) return true
      return false
    },
    delFirstSubAction(actionId,k){
      this.deleteAction(k)
      const action = this.actions.find(el=>el.actionId==actionId && el.copy==true)
      if(action) action.copy=false
      const originalActions = this.actions.filter(el=>el.copy!=true)
      /* if(originalActions.length) this.actionPanel = [originalActions.length-1]
      else this.actionPanel = [] */

    },
    delSubAction(actionId,i, copy){
      if(copy==true){
        this.deleteAction(i)
      }
      else{
          const index = this.actions.findIndex(el=>el.actionId==actionId && el.copy==true)
          if(index<0){
            this.deleteAction(i)
          }
          else{
                  const actionsi = {...this.actions[i].action}
                  const actionsindex = {...this.actions[index].action}
                  this.actions[index].action={...actionsi}
                  this.actions[i].action={...actionsindex}
                  // [this.actions[index],this.actions[i]] = [this.actions[index],this.actions[i]]
                  this.deleteAction(index)

          }
      }


     /*  this.deleteAction(i)
      if(copy!=true){
        const action = this.actions.find(el=>el.actionId==actionId && el.copy==true)
        if(action){
          action.copy=false
          action.pop = pop
          this.$forceUpdate();
        }

      } */
    },
    addSubAction(actionId, index){
        /* this.actions.splice(index+1,0, {
          target:_.cloneDeep(this.actions[index].target),
          actionId:this.actions[index].actionId,
          action: {
                  type: "",
                  aiEmail: [],
                  aiSlack: [],
                  selectAllSlacks: false,
                  selectAllSlackIcon: 'mdi-crop-square',
                  aiOperation: null,
                  aiOperators: null,
                  aiValue: null
                }}) */
        this.actions.push({
          target:_.cloneDeep(this.actions[index].target),
          actionId:this.actions[index].actionId,
          copy:true,
          action: {
                  type: "",
                  aiEmail: [],
                  aiSlack: [],
                  selectAllSlacks: false,
                  selectAllSlackIcon: 'mdi-crop-square',
                  aiOperation: null,
                  aiOperators: null,
                  aiValue: null
                }})
        // this.actions[index+1].copy = true
      //   this.selectedActionOrgs.splice(index+1,0,'');
      // this.aiExtChecked.splice(index+1,0,false);
      this.selectedActionOrgs.push('')
      this.aiExtChecked.push(false)
      this.actionLevelChanged(this.actions.length-1, this.actions[index].target.forType, 'auto')

      this.externalEmailId.push([]);
    },
    extCheckSelection(value) {
      this.addOtherMail = value;
    },
    externalEmailSelection(values) {
      this.externalEmailSelected = !!values.length;
    },
    emailSelections(action) {
      this.emailSelected = !!action.aiEmail;
      if(action.aiEmail.length === this.emailList) action.selectAllEmails = true;
      else action.selectAllEmails = false;
    },
    slackSelections(values) {
      this.slackSelected = !!values.length;
    },
    applyRuleDateRange() {
      this.ruleDateRange = this.ruleDateRangelModel;
      this.ruleRangePicker = false;
    },
    clearRuleDateRange() {
      this.ruleDateRange = [];
      this.ruleDateRangelModel = [];
      this.ruleRangePicker = false;
    },
    applyConditionDateRange() {
      this.conditionDateRange = this.conditionDateRangelModel;
      this.conditionRangePicker = false;
    },
    clearConditionDateRange() {
      this.conditionDateRange = [];
      this.conditionDateRangelModel = [];
      this.conditionRangePicker = false;
    },

    setFieldListonUpdate(index, from) {
      let attr_exist = true;
      let adamIds = [];
      if (LSS && LSS.ownApps.length > 0) {
        const ownApp = LSS.ownApps;
        for (const f of from) {
          if (!f.adamId) {
            attr_exist = false;
            break;
          }
          const app = ownApp.filter((o) => {
            return o.adamId == f.adamId;
          });
          if (!app[0].attrExist) {
            attr_exist = false;
            break;
          }
          adamIds.push(f.adamId);
        }
      } else {
        attr_exist = false;
      }

      if (attr_exist) {
        this.ciFieldList[index] = this.ciFieldListWithMMP;

        //  this.getGoals(index, adamIds);
        //  this.allGoalItems[index] = thi
        const goal = this.setEditGoal(adamIds);
        this.allGoalItems[index] = goal[0] || [];
      } else {
        this.ciFieldList[index] = this.ciFieldListStatic;
      }
    },
    setEditGoal(adamIds) {
      const goal = new Set();
      for (const adamId of adamIds) {
        const a = this.goals.filter((res) => {
          return res.adamId == adamId;
        });
        goal.add(a[0].goals);
      }
      return [...goal];
    },
    resetFromSelectedFromConditions(k) {
      //this.source[k].forType= { levelName: 'Select', levelId: 0, value: ''};
      this.source[k].from = [];
      this.resetConditions(k);
      this.selectedOrgs[k] = null;
    },
    resetConditions(k) {
      this.source[k].conditions = [
        {
          fieldpath: null,
          goalId: null,
          operation: null,
          operator: null,
          fromValue: null,
          toValue: null,
          value: null,
        },
      ];
      this.ciFieldList[k] = this.ciFieldListStatic;
    },
    resetFromSelectedFromActions(k) {
      this.actions[k].target.from = [];
      this.resetActions(k);
      this.selectedActionOrgs[k] = null;
    },
    resetActions(k) {
      this.actions[k].action = {
        type: null,
        aiEmail: [],
        aiSlack: [],
        selectAllSlacks: false,
        selectAllSlackIcon: 'mdi-crop-square',
        aiOperation: null,
        aiOperators: null,
        aiValue: null,
      };
    },
    initEdit() {
      //console.log(this.editData);
      // this.actionPanel=[]
      const data = this.editData;

      const sources = data.sources.map((el) => {
        return {
          ...el,
          forType: this.AllFromLevels.find((e) => {
            return e.value == el.forType;
          }),

          fromType: this.AllFromLevels.find((e) => {
            return e.value == el.forType;
          }),
        };
      });
      this.source = sources;
      this.originalSource = _.cloneDeep(sources)
      for (const [index, value] of sources.entries()) {
        this.levelChanged(index, value.forType, 'auto');
        this.setFieldListonUpdate(index, value.from);
        if (value.forType.value == ForTypes.ORG) {
          const org = this.orgList.filter((e) => {
            return e.orgId == value.from[0].orgId;
          });
          this.selectedOrgs[index] = org[0];
        }
      }
      const tempEmail = this.emailList.map(function (obj) {
        return obj.Email;
      });
      const actions = data.actions.map((el, index) => {
        this.externalEmailId[index] = el.action.aiEmail.filter(function (obj) {
          return tempEmail.indexOf(obj) == -1;
        });
        if (this.externalEmailId[index].length) {
          this.aiExtChecked[index] = true;
        } else {
          this.aiExtChecked[index] = false;
        }
        if(el.action.aiSlack && el.action.aiSlack.length === this.slackChannelList.length){
          el.action.selectAllSlacks = true
          el.action.selectAllSlackIcon = 'mdi-checkbox-multiple-marked'
        }else{
          el.action.selectAllSlacks = false
          el.action.selectAllSlackIcon = 'mdi-crop-square'
        }

        return {
          ...el,
          target: {
            fromType: this.AllFromLevels.find((e) => {
              return e.value == el.target.forType;
            }),
            forType: this.AllFromLevels.find((e) => {
              return e.value == el.target.forType;
            }),
            from: el.target.from,
          },
        };
      });
      if(!actions[0].actionId){
        actions.forEach(el=>{
          if(!el.actionId) el.actionId=uuidv4()
        })
      }
      this.actions = actions;
      this.originalActions = _.cloneDeep(actions)
      for (const [index, value] of actions.entries()) {
        if (value.target.forType.value == 'ORG') {
          const org = this.orgList.filter((el) => {
            return el.orgId == value.target.from[0].orgId;
          });

          this.selectedActionOrgs[index] = org[0];
        }
        this.actionLevelChanged(index, value.target.forType, 'auto');
      }
      this.ruleName = data.ruleName;
      this.settings = data.settings;

      this.isIndefinite = this.settings.ruleSettings.isIndefinite;

      if(this.settings.conditionSettings.dateRange && this.settings.conditionSettings.dateRange.from && this.settings.conditionSettings.dateRange.to) {
        const value = [this.settings.conditionSettings.dateRange.from, this.settings.conditionSettings.dateRange.to];
        this.conditionDateRange = value;
        this.conditionDateRangelModel = value;
      } else {
        this.conditionDateRange = [];
        this.conditionDateRangelModel = [];
      }

      if (this.settings.ruleSettings.isDated) {
        const value = [
          this.settings.ruleSettings.dateRange.from,
          this.settings.ruleSettings.dateRange.to,
        ];
        this.ruleDateRange = value;
        this.ruleDateRangelModel = value;
      } else {
        this.ruleDateRange = [];
        this.ruleDateRangelModel = [];
      }
    },
    close() {
        this.clearAll();
        this.showCreateAR = false;
      },
    pasteSourceSelections() {
      this.actions = [];
        this.source.map((sourceItem, index) => {
          this.actions.push({
                actionId:uuidv4(),
                target: _.pick(sourceItem, ['fromType', 'forType', 'from']),
                action: {
                  type: "",
                  aiEmail: [],
                  aiSlack: [],
                  selectAllEmails: false,
                  selectAllIcon: 'mdi-crop-square',
                  selectAllSlacks: false,
                  selectAllSlackIcon: 'mdi-crop-square',
                  aiOperation: null,
                  aiOperators: null,
                  aiValue: null
                }
                });

                this.actionLevelChanged(index, sourceItem.forType, 'auto')
                this.externalEmailId[index]=[]
                if(sourceItem.forType.levelId==1) this.selectedActionOrgs[index]=sourceItem.from[0]
        });
    },
    pasteSourceSelectionsDuringEdit(){
      //console.log(111, this.source, this.originalSource)
      this.actions = _.cloneDeep(this.originalActions)
      const tempArr =  _.differenceWith(this.source, this.originalSource, _.isEqual);
      //console.log(222,tempArr)
      const originalActionsLength = this.actions.length
      if(tempArr.length){
      tempArr.map((sourceItem, index) => {
                this.actions.push({
                      actionId:uuidv4(),
                      target: _.pick(sourceItem, ['fromType', 'forType', 'from']),
                      action: {
                        type: "",
                        aiEmail: [],
                        aiSlack: [],
                        selectAllEmails: false,
                        selectAllIcon: 'mdi-crop-square',
                        selectAllSlacks: false,
                        selectAllSlackIcon: 'mdi-crop-square',
                        aiOperation: null,
                        aiOperators: null,
                        aiValue: null
                      }
                      });
                      this.actionLevelChanged(originalActionsLength+index, sourceItem.forType, 'auto')
                      this.externalEmailId[originalActionsLength+index]=[]
              });
      }
    },
    toStep2() {
      this.$refs.source.validate();
      if (!this.sourceFormvalid) {
        return;
      }
        if(!this.editData) this.pasteSourceSelections();
        if(this.editData) this.pasteSourceSelectionsDuringEdit()
        this.step = 2;
        if (this.$refs.action) this.$refs.action.resetValidation();
      },
    toStep3() {
      this.$refs.action.validate();
      if(!this.$refs.action.validate()) return;
      if (!this.actionFormvalid) {
        return;
      }
      this.step = 3;
    },
    askConfirm() {
      this.$refs.meta.validate();
      if (!this.metaFormValid) {
        return;
      }
      this.DecisionAlertIcon = 2;
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = ''
      this.decisionAlertMessage=this.$t('createAR.sureToSave')
      this.decisionAlertData = {
        from: 'createRule',
        now: Date.now(),
      };
    },

    // saving rule in db
    decisionOkAction(event) {
      this.decisionAlertFlag = false;
      this.saveRule();
    },
    levelChanged(k, e, mode) {
      this.fromLevels[k] = [];
      for (let index = 0; index <= e.levelId; index++) {
        const element = this.AllFromLevels[index];
        if (e.levelId == 6 && index == 5) break;
        this.fromLevels[k].push(element);
      }
      if (e.levelId == 4) {
        this.fromLevels[k] = [
          { levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
        ];
      } else if (e.levelId == 5) {
        this.fromLevels[k] = [
          //{ levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
          { levelName: 'Keyword(s)', levelId: 5, value: FromTypes.KEYWORD },
        ];
      } else if (e.levelId == 6) {
        this.fromLevels[k] = [
          { levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
        ];
      }
      if (mode == 'manual') this.resetFromSelectedFromConditions(k);
      if (mode == 'auto') {
      }
    },
    sourceFromTypelevelChanged(k) {
      this.resetFromSelectedFromConditions(k);
    },
    actionLevelChanged(k, e, mode) {
      //this.fromLevels = [];
      //this.actions[k].target.fromType = { levelName: 'Select', levelId: 0, value: ''};
      this.actionFromLevels[k] = [];
      for (let index = 0; index <= e.levelId; index++) {
        const element = this.AllFromLevels[index];
        if (e.levelId == 6 && index == 5) break;
        this.actionFromLevels[k].push(element);
      }
      if (e.levelId == 4) {
        this.actionFromLevels[k] = [
          { levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
        ];
      } else if (e.levelId == 5) {
        this.actionFromLevels[k] = [
          //{ levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
          { levelName: 'Keyword(s)', levelId: 5, value: FromTypes.KEYWORD },
        ];
      } else if (e.levelId == 6) {
        this.actionFromLevels[k] = [
          { levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
        ];
      }
      this.conditionalActionListFunc(k, e);
      if (mode == 'manual') this.resetFromSelectedFromActions(k);
    },
    conditionalActionListFunc(k, e) {
      if (e.levelId == 1 || e.levelId == 2) {
        this.conditionalActionList[k] = [
          { action: this.$t('createAR.emailNotification'), id: 1, value: AITypes.EMAIL_NOTIF },
          { action: this.$t('createAR.slackNotification'), id: 2, value: AITypes.SLACK_NOTIF },
        ];
      } else if (e.levelId == 3) {
        this.conditionalActionList[k] = [
          { action: this.$t('createAR.emailNotification'), id: 1, value: AITypes.EMAIL_NOTIF },
          { action: this.$t('createAR.slackNotification'), id: 2, value: AITypes.SLACK_NOTIF },
          { action: this.$t('createAR.pause'), id: 3, value: AITypes.PAUSE },
          { action: this.$t('createAR.resume'), id: 9, value: AITypes.RESUME },
          {
            action: this.$t('createAR.changeDailyCap'),
            id: 4,
            value: AITypes.CHANGE_DAILY_CAP,
          },
          { action: this.$t('createAR.changeBudget'), id: 6, value: AITypes.CHANGE_BUDGET },
        ];
      } else if (e.levelId == 4) {
        this.conditionalActionList[k] = [
          { action: this.$t('createAR.emailNotification'), id: 1, value: AITypes.EMAIL_NOTIF },
          { action: this.$t('createAR.slackNotification'), id: 2, value: AITypes.SLACK_NOTIF },
          { action: this.$t('createAR.pause'), id: 3, value: AITypes.PAUSE },
          { action: this.$t('createAR.resume'), id: 9, value: AITypes.RESUME },
          { action: this.$t('createAR.changeBid'), id: 5, value: AITypes.CHANGE_BID },
        ];
      } else if (e.levelId == 5) {
        this.conditionalActionList[k] = [
          { action: this.$t('createAR.emailNotification'), id: 1, value: AITypes.EMAIL_NOTIF },
          { action: this.$t('createAR.slackNotification'), id: 2, value: AITypes.SLACK_NOTIF },
          { action: this.$t('createAR.pause'), id: 3, value: AITypes.PAUSE },
          { action: this.$t('createAR.resume'), id: 9, value: AITypes.RESUME },
          { action: this.$t('createAR.changeBid'), id: 5, value: AITypes.CHANGE_BID },
          {
            action: this.$t('createAR.addAsNegativeKeyword'),
            id: 8,
            value: AITypes.ADD_AS_NEG_KEYWORD,
          },
        ];
      }
    },
    actionFromTypelevelChanged(k) {
      this.resetFromSelectedFromActions(k);
    },

    addRuleRow(k) {
      this.source.push({
        fromType: { levelName: 'Select', levelId: 0, value: '' },
        forType: { levelName: 'Select', levelId: 0, value: '' },
        andOrType: CIAndORTypes.AND,
        ruleType: 'ASA',
        from: [],
        conditions: [
          {
            fieldpath: null,
            goalId: null,
            operation: null,
            operator: null,
            fromValue: null,
            toValue: null,
            value: null,
          },
        ],
      });

      this.selectedOrgs[k + 1] = null;
      this.conditionPanel = [k+1]
    },
    addActionRow() {
      const length = this.actions.length;
      this.actions.push({
        actionId:uuidv4(),
        target: {
          fromType: { levelName: 'Select', levelId: 0, value: '' },
          forType: { levelName: 'Select', levelId: 0, value: '' },
          from: [],
        },
        action: {
          type: '',
          aiEmail: [],
          aiSlack: [],
          selectAllEmails: false,
          selectAllIcon: 'mdi-crop-square',
          selectAllSlacks: false,
          selectAllSlackIcon: 'mdi-crop-square',
          aiOperation: null,
          aiOperators: null,
          aiValue: null,
        },
      });

      this.selectedActionOrgs[length] = '';
      this.aiExtChecked.push(false);
      this.externalEmailId.push([]);
      this.actionPanel = [length]
    },
    addConditionRow(rowNo, conditionNo) {
      this.source[rowNo].conditions.push({
        fieldpath: null,
        goalId: null,
        operation: null,
        operator: null,
        fromValue: null,
        toValue: null,
        value: null,
      });
    },
    getGoals(k, appId) {
      this.$apollo
        .query({
          query: GET_GOALS_FROM_APP,
          variables: {
            appId: appId,
          },
        })
        .then((res) => {
          this.allGoalItems[k] = res.data.getGoalsFromApp
            ? res.data.getGoalsFromApp
            : [];
        });
    },

    getOrgs() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_CAMPAIGN_GROUPS,
        })
        .then((res) => {
          //console.log(res.data);
          this.orgList = res.data.getCampaignGroups
            ? res.data.getCampaignGroups
            : [];
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },
    getEmails() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_EMAILS,
        })
        .then((res) => {
          //console.log(res.data);
          this.emailList = res.data.getEmails ? res.data.getEmails : [];
          //this.emailList.push({Email: "External"})
          //this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },

    getSlacks() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_SLACKS,
        })
        .then((res) => {
          this.slackChannelList = res.data.getSlacks
          //this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },

    choosePopforFiltered(actionId, target){
      const index = this.actions.findIndex(el=>el.actionId==actionId && !el.copy)
      this.choosePop(index,target)
    },
    //related to pop up
    choosePop(k, option) {
      let from = null;
      if (option == 'source') {
        from = this.source[k].forType.levelId;
      } else {
        from = this.actions[k].target.forType.levelId;
      }
      if (from == 2) {
        this.openAppsPop(k, option);
      } else if (from == 3) {
        this.openCampaignPop(k, option);
      } else if (from == 4) {
        this.openAdGroupPop(k, option);
      } else if (from == 5) {
        this.openKeysPop(k, option);
      } else {
        console.log();
      }
      //this.step = 1
    },
    openKeysPop(k, option) {
      this.toSelectKeys = {
        now: Date.now(),
        rowId: k,
        option: option,
      };
    },
    openAppsPop(k, option) {
      this.toSelectApps = {
        now: Date.now(),
        rowId: k,
        option: option,
      };
    },
    openCampaignPop(k, option) {
      this.selectCampaign = {
        now: Date.now(),
        rowId: k,
        option: option,
      };
    },
    openAdGroupPop(k, option) {
      this.selectAdgroupForChoose = {
        now: Date.now(),
        rowId: k,
        option: option,
      };
    },
    /* openCampaign(index) {
      this.selectCampaignForKeywords = {
        now: Date.now(),
        arIndex: index,
      };
    }, */
    campaignSelectedForChoose(event) {
      const campaigns = this.getNested(event?.toEmit, 'CAMPAIGN');

      const selCampaigns = campaigns.map((x) => {
        const t = {
          name: x.name,
          campaignId: x.id,
          adamId: parseInt(x.adamId),
          orgId: parseInt(x.orgId),
        };
        return t;
      });

      var ids = new Set(selCampaigns.map(d => d.campaignId));

      if (event.option == 'source') {
        let showMMPFileds = true;
        let appIds = new Set();
        if (campaigns && campaigns.length > 0) {
          for (let index = 0; index < campaigns.length; index++) {
            const camp = campaigns[index];
            for (let index1 = 0; index1 < event?.toEmit.length; index1++) {
              const app = event?.toEmit[index1];
              if (+camp.adamId == app.adamId) {
                if (!app.attr_exist) {
                  showMMPFileds = false;
                  break;
                }
                appIds.add(app.adamId);
              }
            }
            if (!showMMPFileds) break;
          }
        }
        if (showMMPFileds) {
          this.getGoals(event?.rowId, [...appIds]);
          this.ciFieldList[event?.rowId] = this.ciFieldListWithMMP;
        } else {
          this.ciFieldList[event?.rowId] = this.ciFieldListStatic;
        }
        this.source[event?.rowId].from = [...selCampaigns, ...this.source[event?.rowId].from.filter(d => !ids.has(d.campaignId))];
      } else {
        this.actions[event?.rowId].target.from = [...selCampaigns, ...this.actions[event?.rowId].target.from.filter(d => !ids.has(d.campaignId))];
      }
    },
    appsSelectedForChoose(e) {
      // this.apps = e;
      const app = e?.selectedApps.map((el) => ({
        adamId: parseInt(el.adamId),
        name: el.appName,
        orgId: el.orgId,
      }));

      var ids = new Set(app.map(d => d.adamId));

      if (e.option == 'source') {
        this.source[e?.rowId].from = [...app, ...this.source[e?.rowId].from.filter(d => !ids.has(d.adamId))];
      } else {
        this.actions[e?.rowId].target.from = [...app, ...this.actions[e?.rowId].target.from.filter(d => !ids.has(d.adamId))];
      }
    },
    keysSelectedForChoose(data) {
      const keyword = data.toEmit.map((el) => ({
        keywordId: el.id,
        adgroupId: el.adgroupId,
        campaignId: el.campId,
        name: el.name,
        orgId: el.orgId,
        adamId: Number(el.adamId)
      }));

      var ids = new Set(keyword.map(d => d.keywordId));

      if (data.option == 'source') {
        let showMMPFileds = true;
        let appIds = new Set();
        if (data.toEmit && data.toEmit.length > 0) {
          for (let index = 0; index < data.toEmit.length; index++) {
            const key = data.toEmit[index];
            if (!key.attr_exist) {
              showMMPFileds = false;
              break;
            }
            appIds.add(parseInt(key.adamId));
            if (!showMMPFileds) break;
          }
        }
        if (showMMPFileds) {
          this.getGoals(data?.rowId, [...appIds]);
          this.ciFieldList[data?.rowId] = this.ciFieldListWithMMP;
        } else {
          this.ciFieldList[data?.rowId] = this.ciFieldListStatic;
        }
        this.source[data?.rowId].from = [...keyword, ...this.source[data?.rowId].from.filter(d => !ids.has(d.keywordId))];
      } else {
        this.actions[data?.rowId].target.from = [...keyword, ...this.actions[data?.rowId].target.from.filter(d => !ids.has(d.keywordId))];
      }
    },
    adgroupsSelectedForChoose(appsWithCampaigns) {
      const adgroups = this.getNested(
        appsWithCampaigns?.toEmit.data,
        'ADGROUP'
      );
      const ad = adgroups.map((el) => ({
        //adamId: parseInt(el.adamId),
        campaignId: el.campaignId,
        adgroupId: el.id,
        name: el.name,
        orgId: el.orgId,
      }));

      var ids = new Set(ad.map(d => d.adgroupId));

      if (appsWithCampaigns.option == 'source') {
        let showMMPFileds = true;
        let appIds = new Set();
        if (adgroups && adgroups.length > 0) {
          for (let index = 0; index < adgroups.length; index++) {
            const camp = adgroups[index];
            for (
              let index1 = 0;
              index1 < appsWithCampaigns?.toEmit.data.length;
              index1++
            ) {
              const app = appsWithCampaigns?.toEmit.data[index1];
              if (+camp.adamId == app.adamId) {
                if (!app.attr_exist) {
                  showMMPFileds = false;
                  break;
                }
                appIds.add(app.adamId);
              }
            }
            if (!showMMPFileds) break;
          }
        }
        if (showMMPFileds) {
          this.getGoals(appsWithCampaigns?.rowId, [...appIds]);
          this.ciFieldList[appsWithCampaigns?.rowId] = this.ciFieldListWithMMP;
        } else {
          this.ciFieldList[appsWithCampaigns?.rowId] = this.ciFieldListStatic;
        }

        this.source[appsWithCampaigns?.rowId].from = [...ad, ...this.source[appsWithCampaigns?.rowId].from.filter(d => !ids.has(d.adgroupId))];
      } else {
        this.actions[appsWithCampaigns?.rowId].target.from = [...ad, ...this.actions[appsWithCampaigns?.rowId].target.from.filter(d => !ids.has(d.adgroupId))];
      }
    },
    getNested(appsWithCampaigns, level: 'APP' | 'CAMPAIGN' | 'ADGROUP') {
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) =>
                      campaign.adgroups.map((ad) =>
                        Object.assign(ad, { adamId: campaign.adamId })
                      )
                    )
                  )
            )
          );
    },
    removefromChoose(k, idx) {
      this.source[k].from.splice(idx, 1);
      if (!this.source[k].from.length) this.resetConditions(k);
    },
    removefromActionChooseFromSubAction(actionId, k, idx){
      for(const [index,action] of this.actions.entries()){
        if (action.actionId == actionId){
          this.removefromActionChoose(index,idx)
        }
      }
    },
    removefromActionChoose(k, idx) {
      this.actions[k].target.from.splice(idx, 1);
      if (!this.actions[k].target.from.length) this.resetActions(k);
    },
    deleteRule(k) {
      this.source.splice(k, 1);
    },
    deleteAction(k) {
      this.actions.splice(k, 1);
      this.aiExtChecked.splice(k, 1);
      this.externalEmailId.splice(k, 1);
      this.conditionalActionList.splice(k,1)
    },
    deleteConditionItem(k, index) {
      this.source[k].conditions.splice(index, 1);
    },
    setRuleOrg(k, org) {
      const org1 = {
        orgId: org.orgId,
        name: org.orgName,
      };
      this.source[k].from = [org1];
    },
    setActionOrg(k, org) {
      const org1 = {
        orgId: org.orgId,
        name: org.orgName,
      };
      this.actions[k].target.from = [org1];
    },
    clearRule(k) {
      this.source.splice(k, 1, {
        fromType: { levelName: 'Select', levelId: 0, value: '' },
        forType: { levelName: 'Select', levelId: 0, value: '' },
        andOrType: CIAndORTypes.AND,
        ruleType: 'ASA',
        from: [],
        conditions: [
          {
            fieldpath: null,
            goalId: null,
            operation: null,
            operator: null,
            fromValue: null,
            toValue: null,
            value: null,
          },
        ],
      });
      /* this.source[k] = {
                fromType: { levelName: 'Select', levelId: 0, value: ''},
                forType: { levelName: 'Select', levelId: 0, value: ''},
                from: [
                ],
              conditions: [
                {
                  fieldpath: "",
                  operation: "",
                  operator: "",
                  fromValue: "",
                  toValue: "",
                  value: ""
                }
              ]
          } */
    },
    //related to pop up
    clearAction(k, actionId) {
      this.actions.splice(k, 1, {
        actionId:actionId,
        target: {
          fromType: { levelName: 'Select', levelId: 0, value: '' },
          forType: { levelName: 'Select', levelId: 0, value: '' },
          from: [],
        },
        action: {
          type: '',
          aiEmail: [],
          aiSlack: [],
          selectAllSlacks: false,
          selectAllSlackIcon: 'mdi-crop-square',
          aiOperation: null,
          aiOperators: null,
          aiValue: null,
        },
      });
      this.aiExtChecked[k] = false;
      this.externalEmailId[k] = [];
      this.removeDuplicate(actionId)
    },

    removeDuplicate(actionId){
      this.actions.map((el, i)=>{
        if (el.actionId == actionId && el.copy==true){
          this.conditionalActionList.splice(i,1)
          this.selectedActionOrgs.splice(i,1)
          this.aiExtChecked.splice(i,1)
          this.externalEmailId.splice(i,1);
        }
      })
      this.actions = this.actions.filter(el=> el.actionId != actionId || (el.actionId == actionId && !el.copy))
    },
    resetAllActions(actionId){
      this.removeDuplicate(actionId);
      const index = this.actions.findIndex(el=>el.actionId==actionId && !el.copy)
      this.actionLevelChanged(
                                  index,
                                  this.actions[index].target.forType,
                                  'manual'
                                )

    },
    clearAll() {
      this.ruleName = null;
      this.rId = null;
      this.editData = null;
      (this.decisionAlertFlag = false), (this.modeText = this.$t('goals.create'));
      this.decisionAlertData = {};
      this.mode = 1;
      this.step = 1;
      this.aiConditionFormvalid = true;
      this.aiItemFormvalid = true;
      this.source = [
        {
          fromType: { levelName: 'Select', levelId: 0, value: '' },
          forType: { levelName: 'Select', levelId: 0, value: '' },
          andOrType: CIAndORTypes.AND,
          ruleType: 'ASA',
          from: [
            /* {
                    orgId: "2244050",
                    campaignId: "123412341234"
                  } */
          ],
          conditions: [
            {
              fieldpath: null,
              goalId: null,
              operation: null,
              operator: null,
              fromValue: null,
              toValue: null,
              value: null,
            },
          ],
        },
      ];
      this.fromLevels = [];
      this.selectedOrgs = [];
      this.selectedActionOrgs = [];

      this.ciFieldList = [
        [
          {
            header: 'ASA Fields',
            divider: true,
          },
          { field: 'Spend', id: 1, type: 1, value: ACFields.SPEND },
          { field: 'Installs', id: 2, type: 2, value: ACFields.INSTALLS },
          { field: 'Impressions', id: 3, type: 2, value: ACFields.IMPRESSIONS },
          { field: 'CPA', id: 4, type: 1, value: ACFields.CPA },
          { field: 'CPT', id: 5, type: 1, value: ACFields.CPT },
          { field: 'TTR', id: 6, type: 2, value: ACFields.TTR },
          {
            field: 'Lat On Installs',
            id: 7,
            type: 2,
            value: ACFields.LAT_ON_INSTALLS,
          },
          {
            field: 'Lat Off Installs',
            id: 8,
            type: 2,
            value: ACFields.LAT_OFF_INSTALLS,
          },
          {
            field: 'Conversion Rate',
            id: 9,
            type: 2,
            value: ACFields.CONVERSION_RATE,
          },
          {
            field: 'New Downloads',
            id: 10,
            type: 2,
            value: ACFields.NEW_DOWNLOADS,
          },
          {
            field: 'Re Downloads',
            id: 11,
            type: 2,
            value: ACFields.RE_DOWNLOADS,
          },
          { field: 'Taps', id: 12, type: 2, value: ACFields.TAPS },
        ],
      ];
      this.allGoalItems = [];
      this.actions = [
        {
          target: {
            fromType: { levelName: 'Select', levelId: 0, value: '' },
            forType: { levelName: 'Select', levelId: 0, value: '' },
            from: [],
          },
          action: {
            type: null,
            aiEmail: [],
            aiSlack: [],
            selectAllSlacks: false,
            selectAllSlackIcon: 'mdi-crop-square',
            aiOperation: null,
            aiOperators: null,
            aiValue: null,
          },
        },
      ];
      this.aiExtChecked = [false];
      this.externalEmailId = [[]];
      this.actionFromLevels = [];
      this.settings = {
        conditionSettings: {
          timeRange: CITimeRanges.LAST_30_DAYS,
          andOr: CIAndORTypes.AND,
        },
        ruleSettings: {
          checkFrequency: RuleCheckFrequencies.EVERY_DAY,
          isDated: null,
          dateRange: null,
        },
      };
      this.conditionDateRangelModel = [];
      this.conditionDateRange = [];
      this.ruleDateRange = [];
      this.ruleDateRangelModel = [];
      if (this.$refs.source) this.$refs.source.resetValidation();
      if (this.$refs.action) this.$refs.action.resetValidation();
      if (this.$refs.meta) this.$refs.meta.resetValidation();
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    saveRule() {
      // this.$refs.ruleSettForm.validate();
      // if (!this.ruleSettFormvalid) {
      //   return;
      // }

      let rType = 'ASA';
      //using destructuring syntax, remove selCon, selType keys(attr) from each object in acItems array
      //keep each obj with remaining attributes
      //then, on each of the the result obj, change ciField to ciField.value

      let rId = uuidv4();
      if (this.mode == 2) {
        rId = this.rId;
      }
      const actions = this.actions.map((el, i) => {
        return {
          ...el,
          target: {
            fromType: el.target.forType.value ? el.target.forType.value : null,
            forType: el.target.forType.value ? el.target.forType.value : null,
            from: el.target.from,
          },
          actionId:el.actionId,
          copy:el.copy?el.copy:false,
          action: {
            type: el.action.type,
            aiEmail: el.action.aiEmail.concat(
              this.externalEmailId[i].filter(
                (item) => el.action.aiEmail.indexOf(item) < 0
              )
            ),
            aiSlack: el.action.aiSlack || [],
            aiOperation: el.action.aiOperation,
            aiOperators: el.action.aiOperators,
            aiValue: el.action.aiValue,
          },
        };
      });
      let sources = this.source.map((el) => {
        return {
          ...el,
          forType: el.forType.value ? el.forType.value : null,
          fromType: el.forType.value ? el.forType.value : null,
        };
      });
      for (let [index, source] of sources.entries()) {
        for (let [i, cond] of source.conditions.entries()) {
          if (
            cond.fieldpath == ACFields.GOAL_EVENT_COUNT ||
            cond.fieldpath == ACFields.COST_PER_GOAL ||
            cond.fieldpath == ACFields.REVENUE_PER_GOAL ||
            cond.fieldpath == ACFields.GOAL_REVENUE ||
            cond.fieldpath == ACFields.GOAL_ROAS ||
            cond == ACFields.GOAL_ARPU ||
            cond == ACFields.GOAL_AMPU
          ) {
            rType = 'MMP';
            sources[index].ruleType = 'MMP';
            sources[index].conditions[i].ruleType = 'MMP';
          } else {
            sources[index].conditions[i].ruleType = 'ASA';
          }
        }
      }
      const settings = this.settings;

      if (this.conditionDateRange && this.conditionDateRange.length === 2) {
        settings.conditionSettings.dateRange = {
          from: this.conditionDateRange[0],
          to: this.conditionDateRange[1],
        };
      } else {
        settings.conditionSettings.dateRange = null;
      }

      settings.ruleSettings.isIndefinite = this.isIndefinite;

      if(this.ruleDateRange && this.ruleDateRange.length === 2) {
        settings.ruleSettings.isDated = true;
        settings.ruleSettings.dateRange = {
          from: this.ruleDateRange[0],
          to: this.ruleDateRange[1],
        };
      } else {
        settings.ruleSettings.isDated = null;
        settings.ruleSettings.dateRange = null;
      }
      // const newActions = actions.map(({pop, ...keepAttrs}) => keepAttrs)
      const finalObj = {
        ruleName: this.ruleName.trim(),
        //startNow: false,
        ruleId: rId,
        ruleType: rType,
        //ruleType: rType,
        arStatus: ARStatus.RUNNING,
        arVersion: 'v2',
        query: '',
        sources: sources,
        actions: actions,
        settings: settings,
      };
      if (this.mode == 1 || this.mode == 5) {
        this.createRule(finalObj);
      } else if (this.mode == 2) {
        this.updateRule(finalObj);
      } else {
      }
    },
    createRule(ruleReq) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .mutate({
          mutation: CREATE_AR_ADV_RULE,
          variables: {
            ruleReq: ruleReq,
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.rCreatedMsg'));
          this.close();
          this.$emit('fromCreateRule', true);
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.rCreationFailMsg'));
          this.close();
          this.$emit('fromCreateRule', false);
        });
    },
    updateRule(ruleReq) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: UPDATE_AR_ADV_RULE,
          variables: {
            ruleId: ruleReq.ruleId,
            ruleReq: ruleReq,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.ruleUpdatedMsg'));
          // this.clearFor();
          this.close();
          this.$emit('fromCreateRule', true);
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.ruleUpdateFailMsg'));
          this.close();
          this.$emit('fromCreateRule', false);
        });
    },
    toggle(i) {
      this.selectAllEmails = ~this.selectAllEmails;

      if (this.selectAllEmails) {
        this.actions[i].action.aiEmail = this.emailList.map((e) => e.Email);
        this.selectAllIcon = 'mdi-checkbox-multiple-marked';
        this.allEmailSelected = true;
      } else {
        this.allEmailSelected = false;
        this.actions[i].action.aiEmail = [];
        this.selectAllIcon = 'mdi-crop-square';
      }
    },
    toggleSlack(i) {
      this.selectAllSlacks = ~ this.selectAllSlacks;
      if (this.selectAllSlacks) {
        this.actions[i].action.aiSlack = this.slackChannelList.map(
          (e) => e.IntegrationID
        );
        this.selectAllSlackIcon = 'mdi-checkbox-multiple-marked';
      } else {
        this.actions[i].action.aiSlack = null;
        this.selectAllSlackIcon = 'mdi-crop-square';
      }
    },
    toggleEmailIndependant(i) {
      if(this.actions[i].action.selectAllEmails) this.actions[i].action.selectAllEmails = false
      else this.actions[i].action.selectAllEmails = true

      if (this.actions[i].action.selectAllEmails) {
        this.allEmailSelected = true;
        this.actions[i].action.aiEmail = this.emailList.map((e) => e.Email);
        this.actions[i].action.selectAllIcon = 'mdi-checkbox-multiple-marked';
      } else {
        this.allEmailSelected = false;
        this.actions[i].action.aiEmail = [];
        this.actions[i].action.selectAllIcon = 'mdi-crop-square';
      }
    },
    toggleSlackIndependant(i) {
      if(this.actions[i].action.selectAllSlacks) this.actions[i].action.selectAllSlacks = false
      else this.actions[i].action.selectAllSlacks = true

      if (this.actions[i].action.selectAllSlacks) {
        this.actions[i].action.aiSlack = this.slackChannelList.map(
          (e) => e.IntegrationID
        );
        this.actions[i].action.selectAllSlackIcon = 'mdi-checkbox-multiple-marked';
      } else {
        this.actions[i].action.aiSlack = null;
        this.actions[i].action.selectAllSlackIcon = 'mdi-crop-square';
      }
    },
  },
  mounted() {
    this.getOrgs();
    this.getEmails();
    this.getSlacks();
  },
});
