











import * as _ from 'lodash';
import Vue from 'vue';
import EslintBuild from 'eslint4b-prebuilt';

import { EditorState } from "@codemirror/state";
import { EditorView, keymap, ViewUpdate } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import { javascript, javascriptLanguage } from '@codemirror/lang-javascript';

import { basicSetup } from "codemirror";
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';
import { HighlightStyle, syntaxHighlighting, syntaxTree } from '@codemirror/language';
import { SyntaxNodeRef } from '@lezer/common';
import { Diagnostic, lintGutter, setDiagnostics } from '@codemirror/lint';
import { tags as t } from '@lezer/highlight';
import { parse } from 'src/utils/util';


export default Vue.extend({
  name: 'ActionEditor',
  props: {
    validate: {
      type: Number,
      default: null
    },
    template: {
      type: String,
      default: null
    },
    insertFromNavigator: {
      type: Object,
      default() {
        return {
          value: '',
          now: Date.now()
      }}
    },
    scriptedArFunctions: {
      type: Array,
      default: () => []
    },
    scriptedArVars: {
      type: Array,
      default: () => []
    },
    miscVars: {
      type: Array,
      default: () => []
    },
    globalVars: {
      type: Array,
      default: () => []
    },
    superGlobals:{
      type: Array,
      default: () => []
    },
    propCompletions: {
      type: Object,
      default: () => { return {} }
    },
    type: String
  },
  data() {
    return {
      cleanLingeringErrors: false,
      declaredVars: {},
      declaredReportVars: {},
      areThereErrors: false,
      startState: {} as EditorState,
      view: {} as EditorView,
    }
  },

  watch: {
    validate: function(value) {
        if(value) {
          this.cleanLingeringErrors = true;
          this.lintAndEmit();
        }
    },
    template: function (value) {
      if (value) {
        this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: value }
        })
      }
    },
    insertFromNavigator: function(value) {
      this.view.dispatch({
        changes: { from: this.view.state.selection.main.head, to: this.view.state.selection.main.head, insert: value.value }
      })
    }
  },

  methods: {
    lintAndEmit() {
      const nodes = [];
      syntaxTree(this.view.state).cursor().iterate((item: SyntaxNodeRef) => {
        const node = _.cloneDeep(item);
        nodes.push(node);
      });
      
      const jsLintdiagnostics = this.lintJs(nodes);
      const esLintdiagnostics = this.lintEs();
      const varLintdiagnostics = this.lintUserVars(nodes);
      const diagnostics = [...jsLintdiagnostics, ...esLintdiagnostics, ...varLintdiagnostics];
      const jsLintSpec = setDiagnostics(this.view.state, diagnostics);
      
      this.view.dispatch(jsLintSpec);
      
      const valid = !diagnostics.length;

      this.declaredVars = new Set()
      this.declaredReportVars = new Set();
      nodes.forEach((node, index) => {
        if(node.name === 'VariableDefinition') {
          this.declaredVars.add(this.view.state.sliceDoc(node.from, node.to))
        }
        if(node.name == 'VariableName' && node.node?.parent?.name == "CallExpression" && (this.view.state.sliceDoc(node.from, node.to)=='CreateReport')){
          for(let i=index; i>=0; i--){
            if(nodes[i].name=='VariableDefinition'){
              this.declaredReportVars.add(this.view.state.sliceDoc(nodes[i].from, nodes[i].to))
            }
          }
        }
      })
      this.$emit('valid', { isValid: valid, diagnostics, code: this.view.state.sliceDoc(), declaredVars: Array.from(this.declaredVars), declaredReportVars: Array.from(this.declaredReportVars) });
    },
    lintJs(nodes: SyntaxNodeRef[]) {
      const diagnostics: Diagnostic[] = [];
      for(let node of nodes) {
        if (!['Script', 'IfStatement',
          'if',
          'ParenthesizedExpression',
          'else',
          'Block',
          'BinaryExpression',
          'MemberExpression',

          'ArrowFunction',
          'FunctionExpression',
          'ParamList',
          'Arrow',
          'function',
          'ReturnStatement',
          'return',
          'static',
          'extern',
         

          'VariableName',
          'VariableDeclaration',
          'VariableDefinition',
          'var',
          'const',
          'let',
          'Equals',
          'NewExpression',
          'new',
          ',',
          'PropertyName',
          'BooleanLiteral',
          'CompareOp',
          'ArithOp',
          'UpdateOp',
          'LogicOp',
          'Number',
          'String',
          '(',
          ')',
          '{',
          '}',
          ';',
          '[',
          ']',
          'ArrayExpression',
          'ForStatement',
          'for',
          'ForSpec',
          'PostfixExpression',
          'ArrowFunction',
          'FunctionExpression',
          'ParamList',
          'Arrow',
          'function',
          'ReturnStatement',
          'return',
          'static',
          'extern',
          'ExpressionStatement',
          'CallExpression',
          'VariableName',
          'AssignmentExpression',
          'ObjectExpression',
          'Property',
          'PropertyDefinition',
          ':',
          'ArgList', '⚠', '.', 'LineComment'].includes(node.name)) {

          const found = diagnostics.find(diagnostic => diagnostic.from <= node.from && diagnostic.to >= node.to);

          if (!found) {
            diagnostics.push({
              from: node.from,
              to: node.to,
              source: 'scripted-ar-linter',
              severity: "error",
              message: node.name + " is prohibited."
            })
          }

        } else if (['⚠'].includes(node.name)) {
          diagnostics.push({
            from: node.from,
            to: node.to,
            source: 'scripted-ar-linter',
            severity: "error",
            message: "Expression expected"
          })
        }
        
        if(node.name === 'VariableName') {
          let variableName = this.view.state.sliceDoc(node.from, node.to) as any;
          
          if(this.miscVars.includes(variableName)) {
              diagnostics.push({
              from: node.from,
              to: node.to,
              source: 'scripted-ar-linter',
              severity: "warning",
              message: "Placeholder variable. Please replace with appropriate value."
          }) 
          }
        }
      }
      return diagnostics;
    },
    lintEs() {
      let globals = {};
      
      const esLintConst = new EslintBuild();

      const rules = { 'no-unused-vars': 0 };
      
      esLintConst.getRules().forEach((desc, name) => {
        if (desc.meta.docs.recommended && rules[name] === undefined)
          rules[name] = 2;
      });
      
      Object.assign(globals, [...this.globalVars].reduce((a, v) => ({ ...a, [v]: 'writable' }), {}));
      Object.assign(globals, [...this.scriptedArVars, ...this.miscVars].reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
      Object.assign(globals, this.scriptedArFunctions.map(fn => fn.split('(')[0]).reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
      const config = {
        parserOptions: { ecmaVersion: 2019, sourceType: "module" },
        env: {},
        rules,
        globals
      };
      let { state } = this.view, found = [];
      for (let { from, to } of javascriptLanguage.findRegions(state)) {
        let fromLine = state.doc.lineAt(from), offset = { line: fromLine.number - 1, col: from - fromLine.from, pos: from };

        for (let d of esLintConst.verify(state.sliceDoc(from, to), config))
          found.push(this.translateDiagnostic(d, state.doc, offset));
      }

      return found as Diagnostic[];
    },
    lintUserVars(nodes: SyntaxNodeRef[]) {
      const diagnostics: Diagnostic[] = [];
      
      for(let [index, node] of nodes.entries()) {
        const nodeBefore = nodes[index - 1];
        
        if(['VariableDefinition', 'VariableDeclaration'].includes(node.name) && nodeBefore && ['var', 'let', 'const'].includes(nodeBefore.name)) {

          const varName = this.view.state.sliceDoc(node.from, node.to);
          
          if(![...this.scriptedArVars, ...this.miscVars].includes(varName) && !varName.startsWith('_')) {
            diagnostics.push({
              from: node.from,
              to: node.to,
              source: 'scripted-ar-linter',
              severity: "error",
              message: "Variable declarations must be prefixed with _"
          })
          }
        }
      }
      
      return diagnostics;
    },
    completeProperties(from: number, object: any, isArray?: boolean) {
      let options = []
      if (isArray) {
        for (let name of object) {
          options.push({
            label: name,
            type: typeof object[name] == "function" ? "function" : "variable"
          })
        }
      } else {
        for (let name in object) {
          options.push({
            label: name,
            type: typeof object[name] == "function" ? "function" : "variable"
          })
        }
      }
      return {
        from,
        options,
        validFor: /^[\w$]*$/
      }
    },
    toSet(chars) {
      let flat = Object.keys(chars).join("");
      let words = /\w/.test(flat);
      if (words)
        flat = flat.replace(/\w/g, "");
      return `[${words ? "\\w" : ""}${flat.replace(/[^\w\s]/g, "\\$&")}]`;
    },

    prefixMatch(options) {
      let first = Object.create(null), rest = Object.create(null);
      for (let { label } of options) {
        first[label[0]] = true;
        for (let i = 1; i < label.length; i++)
          rest[label[i]] = true;
      }
      let source = this.toSet(first) + this.toSet(rest) + "*$";
      return [new RegExp("^" + source), new RegExp(source)];
    },

    completionFromList(context, list: string[], type: string) {
      const options = list.map(p => Object.assign({ label: p, type }));
      const [validFor, match] = options.every(o => /^\w+$/.test(o.label)) ? [/\w*$/, /\w+$/] : this.prefixMatch(options);
      const token = context.matchBefore(match);
      return token || context.explicit ? { from: token ? token.from : context.pos, options, validFor } : null;
    },

    mapPos(line: number, col: number, doc, offset) {
      return doc.line(line + offset.line).from + col + (line == 1 ? offset.col - 1 : -1);
    },

    translateDiagnostic(input, doc, offset) {
      let start = this.mapPos(input.line, input.column, doc, offset);
      let result = {
        from: start,
        to: input.endLine != null && input.endColumn != 1 ? this.mapPos(input.endLine, input.endColumn, doc, offset) : start,
        message: input.message,
        source: input.ruleId ? "eslint:" + input.ruleId : "eslint",
        severity: input.severity == 1 ? "warning" : "error",
      };
      return result;
    },
  },

  mounted() {

    const javascriptLang = javascript();

    // THEME
    const settings = {
      background: '#FFFFFF',
      foreground: '#000000',
      caret: '#000000',
      selection: '#FFFD0054',
      gutterBackground: '#FFFFFF',
      gutterForeground: '#00000070',
      lineHighlight: '#00000008',
    }

    const actionEditorTheme = EditorView.theme({
      '&': {
        backgroundColor: settings.background,
        color: settings.foreground,
      },
      ".cm-scroller": { overflow: "auto" },
      '.cm-content': {
        caretColor: settings.caret,
      },
      '.cm-cursor, .cm-dropCursor': {
        borderLeftColor: settings.caret,
      },
      '&.cm-focused .cm-selectionBackgroundm .cm-selectionBackground, .cm-content ::selection':
      {
        backgroundColor: settings.selection,
      },
      '.cm-activeLine': {
        backgroundColor: settings.lineHighlight,
      },
      '.cm-gutters': {
        backgroundColor: settings.gutterBackground,
        color: settings.gutterForeground,
      },
      '.cm-activeLineGutter': {
        backgroundColor: settings.lineHighlight,
      },
    });

    // Highlight
    const myHighlightStyle = HighlightStyle.define([
      {
        tag: t.comment,
        color: '#CFCFCF',
      },
      {
        tag: [t.number, t.bool, t.null],
        color: '#E66C29',
      },
      {
        tag: [
          t.className,
          t.definition(t.propertyName),
          t.function(t.variableName),
          t.labelName,
          t.definition(t.typeName),
        ],
        color: '#2EB43B',
      },
      {
        tag: t.keyword,
        color: '#D8B229',
      },
      {
        tag: t.operator,
        color: '#4EA44E',
      },
      {
        tag: [t.definitionKeyword, t.modifier],
        color: '#925A47',
      },
      {
        tag: t.string,
        color: '#704D3D',
      },
      {
        tag: t.typeName,
        color: '#2F8996',
      },
      {
        tag: [t.variableName, t.propertyName],
        color: '#77ACB0',
      },
      {
        tag: t.self,
        color: '#77ACB0',
      },
      {
        tag: t.regexp,
        color: '#E3965E',
      },
      {
        tag: [t.tagName, t.angleBracket],
        color: '#BAA827',
      },
      {
        tag: t.attributeName,
        color: '#B06520',
      },
      {
        tag: t.derefOperator,
        color: '#000',
      },
    ]);
    
    const inputListener = EditorView.updateListener.of((v: ViewUpdate) => {
      if(v.docChanged) {
        if(this.cleanLingeringErrors) {
          
          const jsLintSpec = setDiagnostics(this.view.state, []);
      
          this.view.dispatch(jsLintSpec);
          
          this.cleanLingeringErrors = false;
        }
      }
    })

    this.startState = EditorState.create({
      doc: "",
      extensions: [basicSetup, javascriptLang, keymap.of(defaultKeymap), actionEditorTheme, syntaxHighlighting(myHighlightStyle), lintGutter(), inputListener, autocompletion({
        override: [
          (context: CompletionContext) => {
            return this.completionFromList(context, this.scriptedArFunctions, 'function');
          },
          (context: CompletionContext) => {
            return this.completionFromList(context, this.scriptedArVars, 'variable');
          },
          (context: CompletionContext) => {
            return this.completionFromList(context, this.globalVars, 'variable');
          },
          (context: CompletionContext) => {
            const completePropertyAfter = ["PropertyName", "."];
            let nodeBefore = syntaxTree(context.state).resolveInner(context.pos, -1);

            if (completePropertyAfter.includes(nodeBefore.name) &&
              nodeBefore.parent?.name == "MemberExpression") {
              let object = nodeBefore.parent.getChild("Expression")

              if (object?.name == "VariableName") {
                let from = /\./.test(nodeBefore.name) ? nodeBefore.to : nodeBefore.from
                let variableName = context.state.sliceDoc(object.from, object.to) as any;

                if ((this.propCompletions as any)[variableName])
                  return this.completeProperties(from, (this.propCompletions as any)[variableName], true)
              }
            }
            return null
          }
        ]
      })]
    });

    this.view = new EditorView({
      state: this.startState,
      parent: this.$refs.actionEditor as Element,
    })

    if (this.template) {
      this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: this.template }
      });
    }
  }
},);
