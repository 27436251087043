var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", "max-width": "1200px" },
      model: {
        value: _vm.showCreateAR,
        callback: function ($$v) {
          _vm.showCreateAR = $$v
        },
        expression: "showCreateAR",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2 pb-4" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header",
            },
            [
              _vm.mode == 1
                ? _c("h5", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("createAR.createAutoRule"))),
                  ])
                : _vm._e(),
              _c(
                "v-icon",
                { staticClass: "black--text", on: { click: _vm.close } },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-stepper",
            {
              staticClass: "stepper-2 overflow-auto",
              staticStyle: { "box-shadow": "none" },
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "py-3", staticStyle: { "box-shadow": "none" } },
                [
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "rounded py-2 px-2",
                      attrs: {
                        complete: _vm.step > 1,
                        step: "1",
                        editable: "",
                        height: "50px",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("createAR.conditions")) + " ")]
                  ),
                  _c("v-icon", [_vm._v("mdi-chevron-right")]),
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "pa-2",
                      attrs: {
                        complete: _vm.step > 2,
                        step: "2",
                        editable: _vm.sourceFormvalid && _vm.isSourceValid,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("createAR.actions")) + " ")]
                  ),
                  _c("v-icon", [_vm._v("mdi-chevron-right")]),
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "pa-2",
                      attrs: {
                        step: "3",
                        editable: _vm.actionFormvalid && _vm.isActionValid,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("createAR.settings")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-form",
                    {
                      ref: "source",
                      model: {
                        value: _vm.sourceFormvalid,
                        callback: function ($$v) {
                          _vm.sourceFormvalid = $$v
                        },
                        expression: "sourceFormvalid",
                      },
                    },
                    [
                      _c(
                        "v-stepper-content",
                        { staticClass: "pt-0", attrs: { step: "1" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      staticClass: "rule-accordian",
                                      attrs: {
                                        accordion: "",
                                        elevation: "0",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.conditionPanel,
                                        callback: function ($$v) {
                                          _vm.conditionPanel = $$v
                                        },
                                        expression: "conditionPanel",
                                      },
                                    },
                                    _vm._l(_vm.source, function (input, k) {
                                      return _c(
                                        "v-expansion-panel",
                                        { key: k },
                                        [
                                          _c(
                                            "v-expansion-panel-header",
                                            {
                                              staticClass:
                                                "greybase lighten-3 mb-5 d-flex align-center justify-space-between mt-2",
                                            },
                                            [
                                              _c("span", {}, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("createAR.rule")
                                                    )
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    "   " + _vm._s(k + 1) + " "
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "primary--text d-flex justify-end",
                                                },
                                                [
                                                  _vm.source.length > 1
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "primary--text",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteRule(
                                                                k
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-outline"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("v-expansion-panel-content", [
                                            _c(
                                              "div",
                                              { staticClass: " " },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { align: "start" } },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pb-0",
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "field",
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              attrs: {
                                                                items:
                                                                  _vm.forInputRuleFor,
                                                                "item-text":
                                                                  "levelName",
                                                                "item-value":
                                                                  "levelId",
                                                                label: _vm.$t(
                                                                  "labels.createRule"
                                                                ),
                                                                outlined: "",
                                                                dense: "",
                                                                attach: "",
                                                                "return-object":
                                                                  "",
                                                                rules:
                                                                  _vm.vSelectNotNullObjectRules,
                                                              },
                                                              on: {
                                                                change: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.levelChanged(
                                                                    k,
                                                                    input.forType,
                                                                    "manual"
                                                                  )
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  input.forType,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    input,
                                                                    "forType",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "input.forType",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    input.forType.levelId ==
                                                      3 ||
                                                    input.forType.levelId ==
                                                      4 ||
                                                    input.forType.levelId ==
                                                      5 ||
                                                    input.forType.levelId == 2
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "field choose-btn",
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  depressed: "",
                                                                  medium: "",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.choosePop(
                                                                      k,
                                                                      "source"
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createAR.choose"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    input.forType.levelId == 1
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pb-0",
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "field",
                                                              },
                                                              [
                                                                input.forType
                                                                  .levelId != 0
                                                                  ? _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          items:
                                                                            _vm.orgList,
                                                                          "item-text":
                                                                            "orgName",
                                                                          "item-value":
                                                                            "orgId",
                                                                          label: _vm.$t(
                                                                            "labels.org"
                                                                          ),
                                                                          outlined:
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          attach:
                                                                            "",
                                                                          "return-object":
                                                                            "",
                                                                          rules:
                                                                            _vm.vSelectNotNullRules,
                                                                        },
                                                                        on: {
                                                                          change: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.setRuleOrg(
                                                                              k,
                                                                              _vm
                                                                                .selectedOrgs[
                                                                                k
                                                                              ]
                                                                            )
                                                                          },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .selectedOrgs[
                                                                              k
                                                                            ],
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.selectedOrgs,
                                                                              k,
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "selectedOrgs[k]",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    input.from.length > 0
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pb-0",
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "field close-btn d-flex align-center cursor-pointer",
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.clearRule(
                                                                      k
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text",
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-window-close"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "kai.clear"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                input.forType.levelId == 2
                                                  ? _c(
                                                      "v-row",
                                                      { staticClass: "mt-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center pt-0 pb-0 mb-6",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            input.from.length >
                                                            0
                                                              ? _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 d-flex primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createAR.appsSelected"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "field mb-0 d-flex",
                                                              },
                                                              _vm._l(
                                                                input.from,
                                                                function (
                                                                  camp,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "v-chip",
                                                                    {
                                                                      key:
                                                                        camp.adamId,
                                                                      staticStyle: {
                                                                        margin:
                                                                          "0px 10px 0 0",
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "#eee",
                                                                        "text-color":
                                                                          "#222",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            camp.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "primary",
                                                                            "margin-left":
                                                                              "5px",
                                                                          },
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removefromChoose(
                                                                                k,
                                                                                idx
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-close "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                input.forType.levelId == 3
                                                  ? _c(
                                                      "v-row",
                                                      { staticClass: "mt-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center pt-0 pb-0 mb-6",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            input.from.length >
                                                            0
                                                              ? _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 d-flex primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createAR.campaignsSelected"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "field d-flex flex-wrap keyword-wrap",
                                                              },
                                                              _vm._l(
                                                                input.from,
                                                                function (
                                                                  camp,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "v-chip",
                                                                    {
                                                                      key:
                                                                        camp.id,
                                                                      staticStyle: {
                                                                        margin:
                                                                          "0px 10px 5px 0",
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "#eee",
                                                                        "text-color":
                                                                          "#222",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            camp.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "primary",
                                                                            "margin-left":
                                                                              "5px",
                                                                          },
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removefromChoose(
                                                                                k,
                                                                                idx
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-close "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                input.forType.levelId == 4
                                                  ? _c(
                                                      "v-row",
                                                      { staticClass: "mt-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center pt-0 mb-6 pb-0",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            input.from.length >
                                                            0
                                                              ? _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 d-flex primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createAR.adgroupsSelected"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "field d-flex flex-wrap keyword-wrap",
                                                              },
                                                              _vm._l(
                                                                input.from,
                                                                function (
                                                                  camp,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "v-chip",
                                                                    {
                                                                      key:
                                                                        camp.id,
                                                                      staticStyle: {
                                                                        margin:
                                                                          "0px 10px 5px 0",
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "#eee",
                                                                        "text-color":
                                                                          "#222",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            camp.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "primary",
                                                                            "margin-left":
                                                                              "5px",
                                                                          },
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removefromChoose(
                                                                                k,
                                                                                idx
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-close "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                input.forType.levelId == 5
                                                  ? _c(
                                                      "v-row",
                                                      { staticClass: "mt-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center pt-0 mb-6 pb-0",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            input.from.length >
                                                            0
                                                              ? _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2 d-flex primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createAR.KeywordsSelected"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "field d-flex flex-wrap keyword-wrap",
                                                              },
                                                              _vm._l(
                                                                input.from,
                                                                function (
                                                                  camp,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "v-chip",
                                                                    {
                                                                      key:
                                                                        camp.id,
                                                                      staticStyle: {
                                                                        margin:
                                                                          "0px 10px 5px 0",
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "#eee",
                                                                        "text-color":
                                                                          "#222",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            camp.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "primary",
                                                                            "margin-left":
                                                                              "5px",
                                                                          },
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removefromChoose(
                                                                                k,
                                                                                idx
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-close "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-0 mb-5",
                                                        attrs: {
                                                          elevation: "0",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between pa-0",
                                                          },
                                                          [
                                                            _c(
                                                              "h5",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createAR.conditions"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-body-2 primary--text d-flex align-center cursor-pointer",
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addConditionRow(
                                                                          k,
                                                                          input
                                                                            .conditions
                                                                            .length -
                                                                            1
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "addKeys.add"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          input.conditions,
                                                          function (
                                                            condition,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "v-card-text",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "d-flex align-baseline py-3 pt-5 px-3 greyxxlight br-4 br mb-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "count-con d-flex align-center justify-center mr-3",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      align:
                                                                        "center",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          md:
                                                                            "3",
                                                                          sm:
                                                                            "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            staticClass:
                                                                              "v-sel-custom",
                                                                            attrs: {
                                                                              items:
                                                                                _vm
                                                                                  .ciFieldList[
                                                                                  k
                                                                                ],
                                                                              "item-text":
                                                                                "field",
                                                                              "item-value":
                                                                                "value",
                                                                              label: _vm.$t(
                                                                                "createAR.selectField"
                                                                              ),
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              attach:
                                                                                "",
                                                                              rules:
                                                                                _vm.vSelectNotNullRules,
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                condition.fieldpath,
                                                                              callback: function (
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  condition,
                                                                                  "fieldpath",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "condition.fieldpath",
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .GOAL_EVENT_COUNT ||
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .COST_PER_GOAL ||
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .REVENUE_PER_GOAL ||
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .GOAL_REVENUE ||
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .GOAL_ROAS ||
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .GOAL_ARPU ||
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .GOAL_AMPU
                                                                      ? _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "3",
                                                                              sm:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      items:
                                                                                        _vm
                                                                                          .allGoalItems[
                                                                                          k
                                                                                        ],
                                                                                      label: _vm.$t(
                                                                                        "filter.goal"
                                                                                      ),
                                                                                      "item-text":
                                                                                        "name",
                                                                                      "item-value":
                                                                                        "_id",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      attach:
                                                                                        "",
                                                                                      rules:
                                                                                        _vm.vSelectNotNullRules,
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        condition.goalId,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          condition,
                                                                                          "goalId",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "condition.goalId",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          md:
                                                                            "3",
                                                                          sm:
                                                                            "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  items:
                                                                                    _vm.allConOperationItems,
                                                                                  label: _vm.$t(
                                                                                    "createAR.selectOne"
                                                                                  ),
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  attach:
                                                                                    "",
                                                                                  rules:
                                                                                    _vm.vSelectNotNullRules,
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    condition.operation,
                                                                                  callback: function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      condition,
                                                                                      "operation",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "condition.operation",
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .SPEND &&
                                                                    input
                                                                      .forType
                                                                      .levelId ==
                                                                      3
                                                                      ? _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "3",
                                                                              sm:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      items:
                                                                                        _vm.allConOperatorItems,
                                                                                      label: _vm.$t(
                                                                                        "createAR.selectOne"
                                                                                      ),
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      attach:
                                                                                        "",
                                                                                      rules:
                                                                                        _vm.vSelectNotNullRules,
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        condition.operator,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          condition,
                                                                                          "operator",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "condition.operator",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    condition.fieldpath ==
                                                                      _vm
                                                                        .aCFieldsEnum
                                                                        .SPEND &&
                                                                    input
                                                                      .forType
                                                                      .levelId !=
                                                                      3
                                                                      ? _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "px-4",
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "3",
                                                                              sm:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      items:
                                                                                        _vm.allConOperatorItems1,
                                                                                      label: _vm.$t(
                                                                                        "createAR.selectOne"
                                                                                      ),
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      attach:
                                                                                        "",
                                                                                      rules:
                                                                                        _vm.vSelectNotNullRules,
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        condition.operator,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          condition,
                                                                                          "operator",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "condition.operator",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    condition.operation ==
                                                                      _vm
                                                                        .cIOperationsEnum
                                                                        .GREATER_THAN ||
                                                                    condition.operation ==
                                                                      _vm
                                                                        .cIOperationsEnum
                                                                        .LESS_THAN
                                                                      ? _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "3",
                                                                              sm:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  label: _vm.$t(
                                                                                    "createAR.pleaseEnteraValue"
                                                                                  ),
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  type:
                                                                                    "number",
                                                                                  rules:
                                                                                    _vm.emptyStringRule,
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    condition.value,
                                                                                  callback: function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      condition,
                                                                                      "value",
                                                                                      _vm._n(
                                                                                        $$v
                                                                                      )
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "condition.value",
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    condition.operation ==
                                                                      _vm
                                                                        .cIOperationsEnum
                                                                        .IN_RANGE ||
                                                                    condition.operation ==
                                                                      _vm
                                                                        .cIOperationsEnum
                                                                        .NOT_IN_RANGE
                                                                      ? _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "3",
                                                                              sm:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  label: _vm.$t(
                                                                                    "createAR.fromValue"
                                                                                  ),
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  type:
                                                                                    "number",
                                                                                  rules: [
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v <
                                                                                          parseFloat(
                                                                                            condition.toValue
                                                                                          ) ||
                                                                                        _vm.$t(
                                                                                          "createAR.mustBeLesserThanToValue"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v !=
                                                                                          0 ||
                                                                                        _vm.$t(
                                                                                          "createAR.cannotBeZero"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v !=
                                                                                          "" ||
                                                                                        _vm.$t(
                                                                                          "createAR.pleaseEnteraValue"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v !=
                                                                                          null ||
                                                                                        _vm.$t(
                                                                                          "createAR.pleaseEnteraValue"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                                },
                                                                                on: {
                                                                                  keyup: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.validate()
                                                                                  },
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    condition.fromValue,
                                                                                  callback: function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      condition,
                                                                                      "fromValue",
                                                                                      _vm._n(
                                                                                        $$v
                                                                                      )
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "condition.fromValue",
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    condition.operation ==
                                                                      _vm
                                                                        .cIOperationsEnum
                                                                        .IN_RANGE ||
                                                                    condition.operation ==
                                                                      _vm
                                                                        .cIOperationsEnum
                                                                        .NOT_IN_RANGE
                                                                      ? _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "3",
                                                                              sm:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  label: _vm.$t(
                                                                                    "createAR.toValue"
                                                                                  ),
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  type:
                                                                                    "number",
                                                                                  rules: [
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v >
                                                                                          parseFloat(
                                                                                            condition.fromValue
                                                                                          ) ||
                                                                                        _vm.$t(
                                                                                          "createAR.mustBeHigherThanFromValue"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v !=
                                                                                          0 ||
                                                                                        _vm.$t(
                                                                                          "createAR.cannotBeZero"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v !=
                                                                                          "" ||
                                                                                        _vm.$t(
                                                                                          "createAR.pleaseEnteraValue"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      v
                                                                                    ) {
                                                                                      return (
                                                                                        v !=
                                                                                          null ||
                                                                                        _vm.$t(
                                                                                          "createAR.pleaseEnteraValue"
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                                },
                                                                                on: {
                                                                                  keyup: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.validate()
                                                                                  },
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    condition.toValue,
                                                                                  callback: function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      condition,
                                                                                      "toValue",
                                                                                      _vm._n(
                                                                                        $$v
                                                                                      )
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "condition.toValue",
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                                input.conditions
                                                                  .length > 1
                                                                  ? _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "ml-3  d-flex align-center justify-center ",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteConditionItem(
                                                                              k,
                                                                              index
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-delete-outline"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    input.conditions.length > 1
                                                      ? _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  sm: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "mb-6",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createAR.andCondition"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("v-select", {
                                                                  staticClass:
                                                                    "v-sel-custom mt-4",
                                                                  attrs: {
                                                                    items:
                                                                      _vm.andOrTypes,
                                                                    label: _vm.$t(
                                                                      "createAR.selectOne"
                                                                    ),
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    attach: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      input.andOrType,
                                                                    callback: function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        input,
                                                                        "andOrType",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "input.andOrType",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "12" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-3 mb-3",
                                              attrs: {
                                                color: "primary",
                                                depressed: "",
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addRuleRow(
                                                    _vm.source.length - 1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("createAR.addRule")
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mx-1 mb-16 pb-14" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0",
                                  attrs: { cols: "12", md: "6", sm: "12" },
                                },
                                [
                                  _c("label", { staticClass: "mb-6" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("createAR.andCondition"))
                                    ),
                                  ]),
                                  _c("v-select", {
                                    staticClass: "v-sel-custom mt-4",
                                    attrs: {
                                      items: _vm.andOrTypes,
                                      label: _vm.$t("createAR.selectOne"),
                                      outlined: "",
                                      dense: "",
                                      attach: "",
                                      rules: _vm.vSelectNotNullRules,
                                    },
                                    model: {
                                      value:
                                        _vm.settings.conditionSettings.andOr,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings.conditionSettings,
                                          "andOr",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settings.conditionSettings.andOr",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6", sm: "12" } },
                                [
                                  _c("label", { staticClass: "mb-6" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("createAR.checkCond"))
                                    ),
                                  ]),
                                  _c("v-select", {
                                    staticClass: "v-sel-custom mt-4",
                                    attrs: {
                                      items: _vm.ciTimeRanges,
                                      label: _vm.$t("createAR.selectOne"),
                                      outlined: "",
                                      dense: "",
                                      attach: "",
                                      rules: _vm.vSelectNotNullRules,
                                    },
                                    model: {
                                      value:
                                        _vm.settings.conditionSettings
                                          .timeRange,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings.conditionSettings,
                                          "timeRange",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settings.conditionSettings.timeRange",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.settings.conditionSettings.timeRange ==
                          _vm.cITimeRangesEnum.DATE_RANGE
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("createAR.selectDateRange")
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "menu",
                                              attrs: {
                                                "close-on-content-click": false,
                                                "return-value":
                                                  _vm.conditionRangePicker,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "auto",
                                              },
                                              on: {
                                                "update:returnValue": function (
                                                  $event
                                                ) {
                                                  _vm.conditionRangePicker = $event
                                                },
                                                "update:return-value": function (
                                                  $event
                                                ) {
                                                  _vm.conditionRangePicker = $event
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "v-date-picker-cs w-sm-100 mx-2",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "revert",
                                                                },
                                                                attrs: {
                                                                  "prepend-icon":
                                                                    "mdi-calendar",
                                                                  readonly: "",
                                                                  rules:
                                                                    _vm.vSelectNotNullRules,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.conditionDateRangeText,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.conditionDateRangeText = $$v
                                                                  },
                                                                  expression:
                                                                    "conditionDateRangeText",
                                                                },
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1993383698
                                              ),
                                              model: {
                                                value: _vm.conditionRangePicker,
                                                callback: function ($$v) {
                                                  _vm.conditionRangePicker = $$v
                                                },
                                                expression:
                                                  "conditionRangePicker",
                                              },
                                            },
                                            [
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          flex: "auto",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-date-picker",
                                                          {
                                                            attrs: {
                                                              "no-title": "",
                                                              scrollable: "",
                                                              range: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.conditionDateRangelModel,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.conditionDateRangelModel = $$v
                                                              },
                                                              expression:
                                                                "conditionDateRangelModel",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  text: "",
                                                                  color: "grey",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    _vm.conditionRangePicker = false
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createAR.cancel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.conditionDateRangelModel &&
                                                                    _vm
                                                                      .conditionDateRangelModel
                                                                      .length !==
                                                                      2,
                                                                  text: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.applyConditionDateRange()
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "kai.ok"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  text: "",
                                                                  color:
                                                                    "secondary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.clearConditionDateRange,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "kai.clear"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "action",
                      model: {
                        value: _vm.actionFormvalid,
                        callback: function ($$v) {
                          _vm.actionFormvalid = $$v
                        },
                        expression: "actionFormvalid",
                      },
                    },
                    [
                      _c(
                        "v-stepper-content",
                        { staticClass: "pa-6 pt-0", attrs: { step: "2" } },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              staticClass: "rule-accordian mb-16 pb-12",
                              attrs: {
                                accordion: "",
                                elevation: "0",
                                multiple: "",
                              },
                              model: {
                                value: _vm.actionPanel,
                                callback: function ($$v) {
                                  _vm.actionPanel = $$v
                                },
                                expression: "actionPanel",
                              },
                            },
                            [
                              _vm._l(_vm.filteredActions, function (row, k) {
                                return _c(
                                  "v-expansion-panel",
                                  { key: row.actionId },
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      {
                                        staticClass:
                                          "greybase lighten-3 mb-0 d-flex align-center justify-space-between mt-2",
                                      },
                                      [
                                        _c("span", {}, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("createAR.action"))
                                          ),
                                          _c("span", [
                                            _vm._v("   " + _vm._s(k + 1) + " "),
                                          ]),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "primary--text d-flex justify-end",
                                          },
                                          [
                                            _vm.actions.length > 1
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.removeDuplicate(
                                                          row.actionId
                                                        ),
                                                          _vm.deleteAction(
                                                            _vm.actions.findIndex(
                                                              function (el) {
                                                                return (
                                                                  el.actionId ==
                                                                    row.actionId &&
                                                                  !el.copy
                                                                )
                                                              }
                                                            )
                                                          )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-delete-outline")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-expansion-panel-content",
                                      { attrs: { eager: "" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mt-1",
                                            attrs: { align: "center" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "px-3 mt-1",
                                                attrs: { align: "start" },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pb-0",
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "field" },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.forInputRuleFor,
                                                            "item-text":
                                                              "levelName",
                                                            "item-value":
                                                              "levelId",
                                                            label: _vm.$t(
                                                              "createAR.createActionFor"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            attach: "",
                                                            "return-object": "",
                                                            rules:
                                                              _vm.vSelectNotNullObjectRules,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.resetAllActions(
                                                                row.actionId
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              row.target
                                                                .forType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row.target,
                                                                "forType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.target.forType",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                row.target.forType.levelId ==
                                                  3 ||
                                                row.target.forType.levelId ==
                                                  4 ||
                                                row.target.forType.levelId ==
                                                  5 ||
                                                row.target.forType.levelId == 2
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "field choose-btn",
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              depressed: "",
                                                              medium: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.choosePopforFiltered(
                                                                  row.actionId,
                                                                  "target"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "createAR.choose"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                row.target.forType.levelId == 1
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pb-0",
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "field",
                                                          },
                                                          [
                                                            row.target.forType
                                                              .levelId != 0
                                                              ? _c("v-select", {
                                                                  attrs: {
                                                                    items:
                                                                      _vm.orgList,
                                                                    "item-text":
                                                                      "orgName",
                                                                    "item-value":
                                                                      "orgId",
                                                                    label: _vm.$t(
                                                                      "labels.org"
                                                                    ),
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    attach: "",
                                                                    "return-object":
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setActionOrg(
                                                                        k,
                                                                        _vm
                                                                          .selectedActionOrgs[
                                                                          k
                                                                        ]
                                                                      )
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .selectedActionOrgs[
                                                                        k
                                                                      ],
                                                                    callback: function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.selectedActionOrgs,
                                                                        k,
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "selectedActionOrgs[k]",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                row.target.from.length > 0
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pb-0",
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "field close-btn d-flex align-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.resetAllActions(
                                                                  row.actionId
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-window-close"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "kai.clear"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        row.target.forType.levelId == 2
                                          ? _c(
                                              "v-row",
                                              { staticClass: "mt-4" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "text-center pt-0 mb-6 pb-0",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    row.target.from.length > 0
                                                      ? _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "mb-2 d-flex primary--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "createAR.appsSelected"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "field d-flex flex-wrap keyword-wrap",
                                                      },
                                                      _vm._l(
                                                        row.target.from,
                                                        function (camp, idx) {
                                                          return _c(
                                                            "v-chip",
                                                            {
                                                              key: camp.adamId,
                                                              staticStyle: {
                                                                margin:
                                                                  "0px 10px 5px 0",
                                                              },
                                                              attrs: {
                                                                color: "#eee",
                                                                "text-color":
                                                                  "#222",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    camp.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "primary",
                                                                    "margin-left":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removefromActionChooseFromSubAction(
                                                                        row.actionId,
                                                                        k,
                                                                        idx
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.target.forType.levelId == 3
                                          ? _c(
                                              "v-row",
                                              { staticClass: "mt-4" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "text-center pt-0 pb-0 mb-6",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    row.target.from.length > 0
                                                      ? _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "mb-2 d-flex primary--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "createAR.campaignsSelected"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "field d-flex flex-wrap keyword-wrap",
                                                      },
                                                      _vm._l(
                                                        row.target.from,
                                                        function (camp, idx) {
                                                          return _c(
                                                            "v-chip",
                                                            {
                                                              key: camp.id,
                                                              staticStyle: {
                                                                margin:
                                                                  "0px 10px 5px 0",
                                                              },
                                                              attrs: {
                                                                color: "#eee",
                                                                "text-color":
                                                                  "#222",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    camp.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "primary",
                                                                    "margin-left":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removefromActionChooseFromSubAction(
                                                                        row.actionId,
                                                                        k,
                                                                        idx
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.target.forType.levelId == 4
                                          ? _c(
                                              "v-row",
                                              { staticClass: "mt-4" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "text-center pt-0 mb-6 pb-0",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    row.target.from.length > 0
                                                      ? _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "mb-2 d-flex primary--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "createAR.adgroupsSelected"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "field d-flex flex-wrap keyword-wrap",
                                                      },
                                                      _vm._l(
                                                        row.target.from,
                                                        function (camp, idx) {
                                                          return _c(
                                                            "v-chip",
                                                            {
                                                              key: camp.id,
                                                              staticStyle: {
                                                                margin:
                                                                  "0px 10px 5px 0",
                                                              },
                                                              attrs: {
                                                                color: "#eee",
                                                                "text-color":
                                                                  "#222",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    camp.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "primary",
                                                                    "margin-left":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removefromActionChooseFromSubAction(
                                                                        row.actionId,
                                                                        k,
                                                                        idx
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.target.forType.levelId == 5
                                          ? _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "text-center pt-0 mb-2",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    row.target.from.length > 0
                                                      ? _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "mb-2 d-flex",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "createAR.KeywordsSelected"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "field d-flex flex-wrap keyword-wrap",
                                                      },
                                                      _vm._l(
                                                        row.target.from,
                                                        function (camp, idx) {
                                                          return _c(
                                                            "v-chip",
                                                            {
                                                              key: camp.id,
                                                              staticStyle: {
                                                                margin:
                                                                  "0px 10px 5px 0",
                                                              },
                                                              attrs: {
                                                                color: "#eee",
                                                                "text-color":
                                                                  "#222",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    camp.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "primary",
                                                                    "margin-left":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removefromActionChooseFromSubAction(
                                                                        row.actionId,
                                                                        k,
                                                                        idx
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "subaction-outer mt-1 ",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-space-between",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        " mb-1 ml-1 primary--text",
                                                    },
                                                    [_vm._v("Sub Actions ")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text d-flex align-center  cursor-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.addSubAction(
                                                            row.actionId,
                                                            _vm.actions.findIndex(
                                                              function (el) {
                                                                return (
                                                                  el.actionId ==
                                                                  row.actionId
                                                                )
                                                              }
                                                            )
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "pa-1 primary--text ",
                                                          attrs: { color: " " },
                                                        },
                                                        [_vm._v("mdi-plus")]
                                                      ),
                                                      _vm._v(" Add "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.sortedActions,
                                                function (row1, i) {
                                                  return _c(
                                                    "div",
                                                    { key: row1.actionId + i },
                                                    [
                                                      [
                                                        row.actionId ==
                                                        row1.actionId
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "subaction-block greyxxlight br-4 mb-2 py-6 px-3 d-flex align-baseline",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "count-con d-flex align-center justify-center mx-3",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        row1.sort
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "w-100 mx-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-baseline ",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              " pb-0",
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "3",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      items:
                                                                                        _vm
                                                                                          .conditionalActionList[
                                                                                          i
                                                                                        ],
                                                                                      "item-text":
                                                                                        "action",
                                                                                      "item-value":
                                                                                        "value",
                                                                                      label: _vm.$t(
                                                                                        "createAR.selectAction"
                                                                                      ),
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      attach:
                                                                                        "",
                                                                                      rules:
                                                                                        _vm.vSelectNotNullRules,
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        row1
                                                                                          .action
                                                                                          .type,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          row1.action,
                                                                                          "type",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "row1.action.type",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        row1
                                                                          .action
                                                                          .type ==
                                                                        _vm
                                                                          .AITypes
                                                                          .EMAIL_NOTIF
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  " pb-0",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "6",
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom v-sel-custom-rule",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.emailList,
                                                                                          "item-text":
                                                                                            "Email",
                                                                                          "item-value":
                                                                                            "Email",
                                                                                          label: _vm.$t(
                                                                                            "createAR.selectEmail"
                                                                                          ),
                                                                                          multiple:
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                          attach:
                                                                                            "",
                                                                                          chips:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          rules: [
                                                                                            _vm.vSelectEmailRules1.check(
                                                                                              _vm
                                                                                                .aiExtChecked[
                                                                                                k
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                        },
                                                                                        on: {
                                                                                          change: function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.emailSelections(
                                                                                              row1.action
                                                                                            )
                                                                                          },
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            _vm
                                                                                              .emailList
                                                                                              .length >
                                                                                            0
                                                                                              ? {
                                                                                                  key:
                                                                                                    "prepend-item",
                                                                                                  fn: function () {
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "v-divider",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "mt-2",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-list-item",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            ripple:
                                                                                                              "",
                                                                                                          },
                                                                                                          on: {
                                                                                                            click: function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.toggleEmailIndependant(
                                                                                                                i
                                                                                                              )
                                                                                                            },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-list-item-action",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-checkbox",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      "input-value":
                                                                                                                        row1
                                                                                                                          .action
                                                                                                                          .selectAllEmails,
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-list-item-content",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-list-item-title",
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " " +
                                                                                                                        _vm._s(
                                                                                                                          _vm.$t(
                                                                                                                            "createAR.selectAll"
                                                                                                                          )
                                                                                                                        ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ],
                                                                                                        ],
                                                                                                        2
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-divider",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "mt-2",
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  },
                                                                                                  proxy: true,
                                                                                                }
                                                                                              : null,
                                                                                            {
                                                                                              key:
                                                                                                "selection",
                                                                                              fn: function (
                                                                                                ref
                                                                                              ) {
                                                                                                var item =
                                                                                                  ref.item
                                                                                                var index =
                                                                                                  ref.index
                                                                                                return [
                                                                                                  index ===
                                                                                                  0
                                                                                                    ? _c(
                                                                                                        "v-chip",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  item.Email
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  index ===
                                                                                                  1
                                                                                                    ? _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "grey--text text-caption",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " (+" +
                                                                                                              _vm._s(
                                                                                                                row1
                                                                                                                  .action
                                                                                                                  .aiEmail
                                                                                                                  .length -
                                                                                                                  1
                                                                                                              ) +
                                                                                                              " others) "
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                        model: {
                                                                                          value:
                                                                                            row1
                                                                                              .action
                                                                                              .aiEmail,
                                                                                          callback: function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              row1.action,
                                                                                              "aiEmail",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "row1.action.aiEmail",
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        row1
                                                                          .action
                                                                          .type ==
                                                                        _vm
                                                                          .AITypes
                                                                          .SLACK_NOTIF
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  " pb-0",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "3",
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.slackChannelList,
                                                                                          label: _vm.$t(
                                                                                            "createAR.selectChannel"
                                                                                          ),
                                                                                          "no-data-text": _vm.$t(
                                                                                            "createAR.noDataAvailable"
                                                                                          ),
                                                                                          "item-value":
                                                                                            "IntegrationID",
                                                                                          "item-text":
                                                                                            "channel",
                                                                                          attach:
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                          chips:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          multiple:
                                                                                            "",
                                                                                          rules:
                                                                                            _vm.vSelectRules,
                                                                                        },
                                                                                        on: {
                                                                                          change: function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.slackSelections(
                                                                                              row1
                                                                                                .action
                                                                                                .aiSlack
                                                                                            )
                                                                                          },
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            _vm
                                                                                              .slackChannelList
                                                                                              .length >
                                                                                            0
                                                                                              ? {
                                                                                                  key:
                                                                                                    "prepend-item",
                                                                                                  fn: function () {
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "v-divider",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "mt-2",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-list-item",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            ripple:
                                                                                                              "",
                                                                                                          },
                                                                                                          on: {
                                                                                                            click: function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.toggleSlackIndependant(
                                                                                                                i
                                                                                                              )
                                                                                                            },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-list-item-action",
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-icon",
                                                                                                                {
                                                                                                                  attrs: {
                                                                                                                    color: row1
                                                                                                                      .action
                                                                                                                      .aiSlack
                                                                                                                      ? "primary"
                                                                                                                      : "success",
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    " " +
                                                                                                                      _vm._s(
                                                                                                                        row1
                                                                                                                          .action
                                                                                                                          .selectAllSlackIcon
                                                                                                                      ) +
                                                                                                                      " "
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "v-list-item-content",
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-list-item-title",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    " " +
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "createAR.selectAll"
                                                                                                                        )
                                                                                                                      ) +
                                                                                                                      " "
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-divider",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "mt-2",
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  },
                                                                                                  proxy: true,
                                                                                                }
                                                                                              : null,
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                        model: {
                                                                                          value:
                                                                                            row1
                                                                                              .action
                                                                                              .aiSlack,
                                                                                          callback: function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              row1.action,
                                                                                              "aiSlack",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "row1.action.aiSlack",
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              " pb-0",
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "2",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "w-100",
                                                                      },
                                                                      [
                                                                        row1
                                                                          .action
                                                                          .type ==
                                                                        _vm
                                                                          .AITypes
                                                                          .EMAIL_NOTIF
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  " add-email-input  ",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "3",
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs: {
                                                                                      label: _vm.$t(
                                                                                        "createAR.addOtherEmail"
                                                                                      ),
                                                                                      color:
                                                                                        "primary",
                                                                                      "hide-details":
                                                                                        "",
                                                                                    },
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.extCheckSelection(
                                                                                          _vm
                                                                                            .aiExtChecked[
                                                                                            i
                                                                                          ]
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .aiExtChecked[
                                                                                          i
                                                                                        ],
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.aiExtChecked,
                                                                                          i,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiExtChecked[i]",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "w-100",
                                                                        attrs: {
                                                                          align:
                                                                            "center",
                                                                        },
                                                                      },
                                                                      [
                                                                        row1
                                                                          .action
                                                                          .type ==
                                                                          _vm
                                                                            .AITypes
                                                                            .EMAIL_NOTIF &&
                                                                        _vm
                                                                          .aiExtChecked[
                                                                          i
                                                                        ]
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "6",
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-combobox",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      multiple:
                                                                                        "",
                                                                                      label:
                                                                                        "Enter Email",
                                                                                      outlined:
                                                                                        "",
                                                                                      chips:
                                                                                        "",
                                                                                      "deletable-chips":
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      rules:
                                                                                        _vm.csvEmailRules,
                                                                                    },
                                                                                    on: {
                                                                                      change: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.externalEmailSelection(
                                                                                          _vm
                                                                                            .externalEmailId[
                                                                                            i
                                                                                          ]
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .externalEmailId[
                                                                                          i
                                                                                        ],
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.externalEmailId,
                                                                                          i,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "externalEmailId[i]",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    row1.action
                                                                      .type ==
                                                                      _vm
                                                                        .AITypes
                                                                        .CHANGE_DAILY_CAP ||
                                                                    row1.action
                                                                      .type ==
                                                                      _vm
                                                                        .AITypes
                                                                        .CHANGE_BID ||
                                                                    row1.action
                                                                      .type ==
                                                                      _vm
                                                                        .AITypes
                                                                        .CHANGE_BUDGET
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "w-100 mt-5",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "w-100",
                                                                              },
                                                                              [
                                                                                (row1
                                                                                  .target
                                                                                  .forType
                                                                                  .levelId ==
                                                                                  4 ||
                                                                                  row1
                                                                                    .target
                                                                                    .forType
                                                                                    .levelId ==
                                                                                    3) &&
                                                                                (row1
                                                                                  .action
                                                                                  .type ==
                                                                                  _vm
                                                                                    .AITypes
                                                                                    .CHANGE_DAILY_CAP ||
                                                                                  row1
                                                                                    .action
                                                                                    .type ==
                                                                                    _vm
                                                                                      .AITypes
                                                                                      .CHANGE_BID)
                                                                                  ? _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "3",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "v-sel-custom",
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.aiOperationList,
                                                                                                  label: _vm.$t(
                                                                                                    "createAR.selectOne"
                                                                                                  ),
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  rules:
                                                                                                    _vm.vSelectRules,
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    row1
                                                                                                      .action
                                                                                                      .aiOperation,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      row1.action,
                                                                                                      "aiOperation",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "row1.action.aiOperation",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                row1
                                                                                  .target
                                                                                  .forType
                                                                                  .levelId ==
                                                                                  5 &&
                                                                                (row1
                                                                                  .action
                                                                                  .type ==
                                                                                  _vm
                                                                                    .AITypes
                                                                                    .CHANGE_DAILY_CAP ||
                                                                                  row1
                                                                                    .action
                                                                                    .type ==
                                                                                    _vm
                                                                                      .AITypes
                                                                                      .CHANGE_BID)
                                                                                  ? _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "3",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "v-sel-custom",
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.aiOperationList3,
                                                                                                  label: _vm.$t(
                                                                                                    "createAR.selectOne"
                                                                                                  ),
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  rules:
                                                                                                    _vm.vSelectNotNullRules,
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    row1
                                                                                                      .action
                                                                                                      .aiOperation,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      row1.action,
                                                                                                      "aiOperation",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "row1.action.aiOperation",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                row1
                                                                                  .action
                                                                                  .type ==
                                                                                _vm
                                                                                  .AITypes
                                                                                  .CHANGE_BUDGET
                                                                                  ? _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "3",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "v-sel-custom",
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.aiOperationList2,
                                                                                                  label: _vm.$t(
                                                                                                    "createAR.selectOne"
                                                                                                  ),
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  rules:
                                                                                                    _vm.vSelectNotNullRules,
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    row1
                                                                                                      .action
                                                                                                      .aiOperation,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      row1.action,
                                                                                                      "aiOperation",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "row1.action.aiOperation",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                row1
                                                                                  .action
                                                                                  .aiOperation !=
                                                                                  _vm
                                                                                    .aIOperationsEnum
                                                                                    .SET_TO_AVG_CPA &&
                                                                                row1
                                                                                  .action
                                                                                  .aiOperation !=
                                                                                  _vm
                                                                                    .aIOperationsEnum
                                                                                    .SET_TO_AVG_CPT
                                                                                  ? _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "3",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "v-sel-custom",
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.aiOperatorsList,
                                                                                                  label: _vm.$t(
                                                                                                    "createAR.selectOne"
                                                                                                  ),
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  rules:
                                                                                                    _vm.vSelectNotNullRules,
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    row1
                                                                                                      .action
                                                                                                      .aiOperators,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      row1.action,
                                                                                                      "aiOperators",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "row1.action.aiOperators",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                row1
                                                                                  .action
                                                                                  .aiOperation !=
                                                                                  _vm
                                                                                    .aIOperationsEnum
                                                                                    .SET_TO_AVG_CPA &&
                                                                                row1
                                                                                  .action
                                                                                  .aiOperation !=
                                                                                  _vm
                                                                                    .aIOperationsEnum
                                                                                    .SET_TO_AVG_CPT
                                                                                  ? _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "3",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          {
                                                                                            staticClass:
                                                                                              "v-sel-custom",
                                                                                            attrs: {
                                                                                              label: _vm.$t(
                                                                                                "createAR.amount"
                                                                                              ),
                                                                                              outlined:
                                                                                                "",
                                                                                              dense:
                                                                                                "",
                                                                                              type:
                                                                                                "number",
                                                                                              rules:
                                                                                                _vm.emptyStringRule,
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                row1
                                                                                                  .action
                                                                                                  .aiValue,
                                                                                              callback: function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  row1.action,
                                                                                                  "aiValue",
                                                                                                  _vm._n(
                                                                                                    $$v
                                                                                                  )
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "row1.action.aiValue",
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                row1
                                                                                  .action
                                                                                  .aiOperation ==
                                                                                  _vm
                                                                                    .aIOperationsEnum
                                                                                    .SET_TO_AVG_CPA ||
                                                                                row1
                                                                                  .action
                                                                                  .aiOperation ==
                                                                                  _vm
                                                                                    .aIOperationsEnum
                                                                                    .SET_TO_AVG_CPT
                                                                                  ? _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "3",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          {
                                                                                            staticClass:
                                                                                              "v-sel-custom",
                                                                                            attrs: {
                                                                                              label:
                                                                                                "Default amount",
                                                                                              outlined:
                                                                                                "",
                                                                                              dense:
                                                                                                "",
                                                                                              type:
                                                                                                "number",
                                                                                              prefix:
                                                                                                "$",
                                                                                              rules:
                                                                                                _vm.emptyStringRule,
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                row1
                                                                                                  .action
                                                                                                  .aiValue,
                                                                                              callback: function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  row1.action,
                                                                                                  "aiValue",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "row1.action.aiValue",
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.visibleDelButton(
                                                                  row1.actionId
                                                                )
                                                                  ? _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top:
                                                                            "",
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "mr-3",
                                                                                          attrs: {
                                                                                            color:
                                                                                              "primary",
                                                                                          },
                                                                                          on: {
                                                                                            click: function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.delSubAction(
                                                                                                row1.actionId,
                                                                                                i,
                                                                                                row1.copy
                                                                                              )
                                                                                            },
                                                                                          },
                                                                                        },
                                                                                        "v-icon",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-delete-outline"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Delete Sub Action"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        _c("div"),
                                        _c(
                                          "v-row",
                                          [
                                            _c("v-col", {
                                              attrs: { cols: "12", sm: "12" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "v-row",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-start",
                                      attrs: { cols: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            depressed: "",
                                            large: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addActionRow()
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-plus")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("createAR.addAction")
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "meta",
                      model: {
                        value: _vm.metaFormValid,
                        callback: function ($$v) {
                          _vm.metaFormValid = $$v
                        },
                        expression: "metaFormValid",
                      },
                    },
                    [
                      _c(
                        "v-stepper-content",
                        { staticClass: "pa-6 pt-0", attrs: { step: "3" } },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              staticClass: "rule-accordian",
                              attrs: { accordion: "", elevation: "0" },
                              model: {
                                value: _vm.settingPanel,
                                callback: function ($$v) {
                                  _vm.settingPanel = $$v
                                },
                                expression: "settingPanel",
                              },
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "greybase lighten-3 mb-5 d-flex align-center justify-space-between mt-2",
                                    },
                                    [
                                      _c("span", {}, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("createAR.settings"))
                                        ),
                                        _c("span"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "12" } },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("createAR.ruleName")
                                                  )
                                                ),
                                              ]),
                                              _c("v-text-field", {
                                                staticClass:
                                                  "v-sel-custom mt-4",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "createAR.giveAName"
                                                  ),
                                                  outlined: "",
                                                  dense: "",
                                                  rules: _vm.nameRule,
                                                },
                                                model: {
                                                  value: _vm.ruleName,
                                                  callback: function ($$v) {
                                                    _vm.ruleName = $$v
                                                  },
                                                  expression: "ruleName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "12" } },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("createAR.chckFreq")
                                                  )
                                                ),
                                              ]),
                                              _c("v-select", {
                                                staticClass:
                                                  "v-sel-custom mt-4",
                                                attrs: {
                                                  items: _vm.actionFreqs,
                                                  label: _vm.$t(
                                                    "createAR.chckFreq"
                                                  ),
                                                  outlined: "",
                                                  dense: "",
                                                  attach: "",
                                                  rules:
                                                    _vm.vSelectNotNullRules,
                                                },
                                                model: {
                                                  value:
                                                    _vm.settings.ruleSettings
                                                      .checkFrequency,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.settings.ruleSettings,
                                                      "checkFrequency",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "settings.ruleSettings.checkFrequency",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "12" } },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createAR.selectDateRange"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      ref: "menu",
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "return-value":
                                                          _vm.ruleRangePicker,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "min-width": "auto",
                                                      },
                                                      on: {
                                                        "update:returnValue": function (
                                                          $event
                                                        ) {
                                                          _vm.ruleRangePicker = $event
                                                        },
                                                        "update:return-value": function (
                                                          $event
                                                        ) {
                                                          _vm.ruleRangePicker = $event
                                                        },
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "v-date-picker-cs w-sm-100 mx-0",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "revert",
                                                                      },
                                                                      attrs: {
                                                                        "prepend-icon":
                                                                          "mdi-calendar",
                                                                        readonly:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.ruleDateRangeText,
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.ruleDateRangeText = $$v
                                                                        },
                                                                        expression:
                                                                          "ruleDateRangeText",
                                                                      },
                                                                    },
                                                                    "v-text-field",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                      model: {
                                                        value:
                                                          _vm.ruleRangePicker,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.ruleRangePicker = $$v
                                                        },
                                                        expression:
                                                          "ruleRangePicker",
                                                      },
                                                    },
                                                    [
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  flex: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-date-picker",
                                                                  {
                                                                    attrs: {
                                                                      "no-title":
                                                                        "",
                                                                      scrollable:
                                                                        "",
                                                                      range: "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.ruleDateRangelModel,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.ruleDateRangelModel = $$v
                                                                      },
                                                                      expression:
                                                                        "ruleDateRangelModel",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text:
                                                                            "",
                                                                          color:
                                                                            "grey",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            _vm.ruleRangePicker = false
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createAR.cancel"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.ruleDateRangelModel &&
                                                                            _vm
                                                                              .ruleDateRangelModel
                                                                              .length !==
                                                                              2,
                                                                          text:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.applyRuleDateRange()
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.ok"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text:
                                                                            "",
                                                                          color:
                                                                            "secondary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.clearRuleDateRange,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.clear"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12", sm: "12" },
                                            },
                                            [
                                              [
                                                _c(
                                                  "v-container",
                                                  {
                                                    staticClass: "px-0 py-0",
                                                    attrs: { fluid: "" },
                                                  },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "createAR.runIndefinite"
                                                        ),
                                                        "persistent-hint": "",
                                                        hint: _vm.$t(
                                                          "createAR.indefiniteNote"
                                                        ),
                                                      },
                                                      model: {
                                                        value: _vm.isIndefinite,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.isIndefinite = $$v
                                                        },
                                                        expression:
                                                          "isIndefinite",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { horizontal: "" } }),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-0 pa-5 no-border-top" },
            [
              _vm.mode != 3
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "grey",
                        depressed: "",
                        outlined: "",
                      },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("createAR.cancel")) + " ")]
                  )
                : _vm._e(),
              _vm.mode == 3
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "grey",
                        depressed: "",
                        outlined: "",
                      },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("integration.close")) + " ")]
                  )
                : _vm._e(),
              _vm.step == 3 && _vm.mode != 3
                ? _c(
                    "v-btn",
                    {
                      attrs: { width: "127", color: "primary", depressed: "" },
                      on: {
                        click: function ($event) {
                          return _vm.askConfirm()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.modeText) + " ")]
                  )
                : _vm._e(),
              _vm.step == 1
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "primary",
                        disabled: !_vm.isSourceValid,
                        depressed: "",
                      },
                      on: { click: _vm.toStep2 },
                    },
                    [_vm._v(" > " + _vm._s(_vm.$t("createAR.step2")) + " ")]
                  )
                : _vm._e(),
              _vm.step == 2
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "primary",
                        disabled: !_vm.isActionValid,
                        depressed: "",
                      },
                      on: { click: _vm.toStep3 },
                    },
                    [_vm._v(" > " + _vm._s(_vm.$t("createAR.step3")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("SelectCampaign", {
            attrs: { openSelectCampaign: _vm.selectCampaign },
            on: {
              onCampaignSelection: function ($event) {
                return _vm.campaignSelectedForChoose($event)
              },
            },
          }),
          _c("SelectAdGroup", {
            attrs: { settings: _vm.selectAdgroupForChoose },
            on: {
              onAdgroupSelection: function ($event) {
                return _vm.adgroupsSelectedForChoose($event)
              },
            },
          }),
          _c("SelectApps", {
            attrs: { selectApps: _vm.toSelectApps },
            on: {
              onAppSelection: function ($event) {
                return _vm.appsSelectedForChoose($event)
              },
            },
          }),
          _c("SelectKeywords", {
            attrs: { selectKeywordData: _vm.toSelectKeys },
            on: {
              onkeywordSelection: function ($event) {
                return _vm.keysSelectedForChoose($event)
              },
            },
          }),
          _c("DecisionAlert", {
            attrs: {
              DecisionAlert: _vm.decisionAlertFlag,
              DecisionAlertTitle: _vm.decisionAlertTitle,
              DecisionAlertMessage: _vm.decisionAlertMessage,
              DecisionAlertData: _vm.decisionAlertData,
              DecisionAlertIcon: _vm.DecisionAlertIcon,
            },
            on: {
              emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
              emitDecisionAlertOkClicked: function ($event) {
                return _vm.decisionOkAction($event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }